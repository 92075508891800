import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Loading from "../components/Global/Loading";
import { toast } from 'react-toastify';
import axios from "axios";
import moment from "moment-timezone";
import "./Styles/page_profile.css";
import ReactCountryFlag from "react-country-flag";
import VerificationSteps from "./VerificationSteps";
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import Tooltip from '@mui/material/Tooltip';
import "./Styles/page_withdraw.css";

const CreditFeaturesChange = () => {

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    const location = useLocation();
    const query = useQuery();
    const navigate = useNavigate();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const [customerId, setCustomerId] = useState(query.get("customer_id") ? query.get("customer_id") : null);
    const [customerInfo, setCustomerInfo] = useState(null);
    const [customerFutureProfileInfo, setCustomerFutureProfileInfo] = useState(null);

    const [balance, setBalance] = useState(null);
    const [total_surety, setTotalSurety] = useState(null);
    const [credit, setCredit] = useState(null);
    const [credit_available, setCreditAvailable] = useState(null);
    const [credit_deposit, setCreditDeposit] = useState(null);
    const [credit_status, setCreditStatus] = useState(null);
    const [credit_active, setCreditActive] = useState(null);
    const [has_future_profile, setHasFutureProfile] = useState(null);
    const [future_permission, setFuturePermission] = useState(null);

    const [showCreditDialog, setShowCreditDialog] = useState(false);
    const [newCredit, setNewCredit] = useState(0);
    const [newCreditDeposit, setNewCreditDeposit] = useState(0);

    useEffect(() => {//customerId gelirse
        if (customerId) {
            getCustomerInfo();
        }
        else {
            navigate(`/profile`);
        }
    }, [customerId]);

    useEffect(() => {
        if (customerInfo && customerInfo != "User not found" && customerFutureProfileInfo == null) {
            getCustomerFutureProfileInfo();
        }
    }, [customerInfo]);



    const getCustomerInfo = async () => {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        try {
            const response = await axios.get(`https://operationapi.kpriceapp.com/customer?customer_id=${customerId}`, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });

            if (response.status == "200") {
                if (response.data && response.data.customer && response.data.customer[0]) {
                    let newData = response.data.customer[0];
                    newData.status_level = Number(newData.status_level);
                    newData.registration_time = Number(newData.registration_time);
                    newData.registration_date = moment(newData.registration_time).tz(timeZone).format("YYYY-MM-DD HH:mm");
                    setCustomerInfo(response.data.customer[0]);
                }
                else {
                    setCustomerInfo("User not found");
                }
            }
            else {
                setCustomerInfo("User not found");
            }
        }
        catch (error) {
            setCustomerInfo("User not found");
            if (!(error && error.response && error.response.data && error.response.data.error && error.response.data.error == "Invalid User")) {
                toast.error("There was a problem loading customer information!");
            }
        }
    }

    const getCustomerFutureProfileInfo = async () => {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        try {
            const response = await axios.get(`https://operationapi.kpriceapp.com/futureprofile?type=user&customer_id=${customerId}`, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });

            if (response.status == "200" && response.data) {
                console.log(response.data);
                const newData = response.data;
                if (newData.profile_items && Array.isArray(newData.profile_items) && newData.profile_items.length > 0) {
                    const profile = newData.profile_items[0];
                    setBalance(parseFloat(profile.balance));
                    setTotalSurety(parseFloat(profile.total_surety));
                    setCredit(parseFloat(profile.credit));
                    setCreditAvailable(profile.credit_available === 'True');
                    setCreditDeposit(parseFloat(profile.credit_deposit));
                    setCreditStatus(profile.credit_status || null);
                    setCreditActive(profile.credit_active === 'True');
                    setHasFutureProfile(profile.has_future_profile === 'True');
                    setFuturePermission(profile.future_permission === 'True');
                }

                setCustomerFutureProfileInfo(newData);
            }
            else {
                toast.error("There was a problem loading the customer's verification information!");
            }
        }
        catch (error) {
            toast.error("There was a problem loading the customer's verification information!");
        }
    }

    const handleAddCredit = () => {
        setShowCreditDialog(true);
    }

    const handleUpdateCredit = async () => {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        try {
            const response = await axios.post(`https://operationapi.kpriceapp.com/futureprofile/credit-features-change`, {
                customer_id: customerId.toString(),
                update_type: "add_credit",
                updated_credit: newCredit.toString(),
                updated_credit_deposit: newCreditDeposit.toString()
            }, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });

            if (response.status === 200) {
                toast.success("Credit updated successfully!");
                setShowCreditDialog(false);
                getCustomerFutureProfileInfo(); // Güncellenen veriyi yeniden yükleyin
            } else {
                toast.error("Failed to update credit!");
            }
        } catch (error) {
            toast.error("There was a problem updating the credit information!");
        }
    }

    const handleOpenCreditAvailable = () => {
        confirmDialog({
            message: 'Are you sure you want to open credit available?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => updateCreditAvailable("open_credit_available"),
        });
    }

    const handleCloseCreditAvailable = () => {
        confirmDialog({
            message: 'Are you sure you want to close credit available?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => updateCreditAvailable("close_credit_available"),
        });
    }

    const handleRemoveCredit = () => {
        confirmDialog({
            message: 'Are you sure you want to remove credit?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => removeCredit(),
        });
    }
    

    const updateCreditAvailable = async (updateType) => {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        try {
            const response = await axios.post(`https://operationapi.kpriceapp.com/futureprofile/credit-features-change`, {
                customer_id: customerId.toString(),
                update_type: "change_credit_available",
                updated_credit_available: updateType
            }, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });

            if (response.status === 200) {
                toast.success(`Credit available status updated to ${updateType.replace('_', ' ')}`);
                getCustomerFutureProfileInfo();
            } else {
                toast.error("Failed to update credit available status!");
            }
        } catch (error) {
            toast.error("There was a problem updating the credit available status!");
        }
    }    

    const removeCredit = async () => {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        try {
            const response = await axios.post(`https://operationapi.kpriceapp.com/futureprofile/credit-features-change`, {
                customer_id: customerId.toString(),
                update_type: "remove_credit"
            }, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });

            if (response.status === 200) {
                toast.success("Credit removed successfully!");
                getCustomerFutureProfileInfo();
            } else {
                toast.error("Failed to remove credit!");
            }
        } catch (error) {
            toast.error("There was a problem removing the credit!");
        }
    }

    const handleDoActive = () => {
        confirmDialog({
            message: 'Are you sure you want to activate credit status?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => updateCreditStatus("do_active"),
        });
    }

    const handleDoDeactive = () => {
        confirmDialog({
            message: 'Are you sure you want to deactivate credit status?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => updateCreditStatus("do_deactive"),
        });
    }

    const updateCreditStatus = async (statusType) => {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        try {
            const response = await axios.post(`https://operationapi.kpriceapp.com/futureprofile/credit-features-change`, {
                customer_id: customerId.toString(),
                update_type: "change_credit_status",
                updated_credit_status: statusType
            }, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });

            if (response.status === 200) {
                toast.success(`Credit status updated to ${statusType.replace('_', ' ')}`);
                getCustomerFutureProfileInfo();
            } else {
                toast.error("Failed to update credit status!");
            }
        } catch (error) {
            toast.error("There was a problem updating the credit status!");
        }
    }

    const isAddCreditButtonDisabled = () => {
        return (
            !credit_available ||
            credit_status !== 'active' ||
            credit_active ||
            credit !== 0 ||
            credit_deposit !== 0
        );
    }

    const isOpenCreditAvailableButtonDisabled = () => {
        return (
            credit_available ||
            credit_status !== 'active' ||
            credit_active ||
            credit !== 0 ||
            credit_deposit !== 0
        );
    }

    const isCloseCreditAvailableButtonDisabled = () => {
        return (
            !credit_available ||
            credit_status !== 'active'
        );
    }

    const isRemoveCreditButtonDisabled = () => {
        return (
            !credit_active ||
            credit_status !== 'active'
        );
    }

    const isDoActiveButtonDisabled = () => {
        return (
            credit_status !== 'deactive'
        );
    }

    const isDoDeactiveButtonDisabled = () => {
        return (
            credit_status !== 'active' ||
            credit_active ||
            credit !== 0 ||
            credit_deposit !== 0
        );
    }

    const SuccessIcon = () => {
        return <div className="status-icon success"><i className="fa-solid fa-circle-check"></i></div>;
    }

    const WarningIcon = () => {
        return <div className="status-icon warning" title="Customer needs to complete verification steps"><i className="fa-solid fa-circle-exclamation"></i></div>;
    }

    const DangerIcon = () => {
        return <div className="status-icon danger"><i className="fa-solid fa-circle-xmark"></i></div>;
    }

    const SuccessIconArea = () => {
        return <div className="title-icon approved"><i className="fa-solid fa-circle-check"></i>Approved</div>;
    }

    const PendingIconArea = () => {
        return <div className="title-icon pending"><i className="fa-solid fa-clock"></i>Pending</div>;
    }

    const DeniedIconArea = () => {
        return <div className="title-icon denied"><i className="fa-solid fa-circle-xmark"></i>Denied</div>;
    }

    return (
        <div id="main" className="main">
            <div className="allsections allsections-border scrollbarhide section-withdraw">
                {customerId && customerInfo !== "User not found" ? (
                    <>
                        {/* Kullanıcı Bilgisi Alanı */}
                        <div className={`withdraw-user ${!(customerInfo && customerInfo !== "User not found" && has_future_profile && future_permission) && "no-permission"}`}>
                            <div className="withdraw-user-inner">
                                <div>
                                    <i className="fa-solid fa-user"></i>
                                    <span>{customerId ? customerId : "-"}</span>
                                    <Tooltip title="Profile" className="detail-button" placement="right">
                                        <a href={`/profile?customer_id=${customerId}`} target="_blank">
                                            <i className="fa-solid fa-user"></i>
                                        </a>
                                    </Tooltip>
                                </div>
                                {customerInfo && customerInfo !== "User not found" && customerInfo.full_name && <div>{customerInfo.full_name}</div>}
                            </div>
                        </div>

                        {customerFutureProfileInfo ? (
                            <>
                                {has_future_profile && future_permission ? (
                                    <>
                                        <div className="profile-data" style={{ marginLeft: '60px' ,width: '350px'}}>
                                            <h3>Customer Information</h3>
                                            <p><strong>Balance:</strong> {balance !== null ? balance.toFixed(2) : 'N/A'}</p>
                                            <p><strong>Total Surety:</strong> {total_surety !== null ? total_surety.toFixed(2) : 'N/A'}</p>
                                            <p>
                                                <strong>Credit:</strong>
                                                {credit !== null ? credit.toFixed(2) : 'N/A'}
                                                <Tooltip
                                                    title={
                                                        !credit_available ? 'Credit available must be true.' :
                                                            credit_status !== 'active' ? 'Credit status must be active.' :
                                                                credit_active ? 'Credit must not be active.' :
                                                                    credit !== 0 ? 'Credit must be 0.' :
                                                                        credit_deposit !== 0 ? 'Credit deposit must be 0.' :
                                                                            ''
                                                    }
                                                    disableHoverListener={!isAddCreditButtonDisabled()} // Tooltip sadece buton pasif olduğunda gösterilecek
                                                >
                                                    <span style={{ float: "right" }}>
                                                        <button
                                                            className="add-credit-btn"
                                                            onClick={handleAddCredit}
                                                            disabled={isAddCreditButtonDisabled()}
                                                        >
                                                            Add Credit
                                                        </button>
                                                    </span>
                                                </Tooltip>
                                            </p>
                                            <p><strong>Credit Available:</strong> {credit_available !== null ? (credit_available ? 'Yes' : 'No') : 'N/A'}</p>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                                <Tooltip
                                                    title={
                                                        !isOpenCreditAvailableButtonDisabled() ? '' :
                                                            credit_available ? 'Credit available is already true.' :
                                                                credit_status !== 'active' ? 'Credit status must be active.' :
                                                                    credit_active ? 'Credit must not be active.' :
                                                                        credit !== 0 ? 'Credit must be 0.' :
                                                                            credit_deposit !== 0 ? 'Credit deposit must be 0.' :
                                                                                ''
                                                    }
                                                    disableHoverListener={!isOpenCreditAvailableButtonDisabled()}
                                                >
                                                    <span>
                                                        <button
                                                            className="open-credit-available-btn"
                                                            onClick={handleOpenCreditAvailable}
                                                            disabled={isOpenCreditAvailableButtonDisabled()}
                                                        >
                                                            Open Credit Available
                                                        </button>
                                                    </span>
                                                </Tooltip>
                                                <Tooltip
                                                    title={
                                                        !isCloseCreditAvailableButtonDisabled() ? '' :
                                                            !credit_available ? 'Credit available is already false.' :
                                                                credit_status !== 'active' ? 'Credit status must be active.' :
                                                                    ''
                                                    }
                                                    disableHoverListener={!isCloseCreditAvailableButtonDisabled()}
                                                >
                                                    <span>
                                                        <button
                                                            className="close-credit-available-btn"
                                                            onClick={handleCloseCreditAvailable}
                                                            disabled={isCloseCreditAvailableButtonDisabled()}
                                                        >
                                                            Close Credit Available
                                                        </button>
                                                    </span>
                                                </Tooltip>
                                            </div>
                                            <p><strong>Credit Deposit:</strong> {credit_deposit !== null ? credit_deposit.toFixed(2) : 'N/A'}</p>
                                            <p><strong>Credit Status:</strong> {credit_status || 'N/A'}</p>
                                            <p><strong>Credit Active:</strong> {credit_active !== null ? (credit_active ? 'Yes' : 'No') : 'N/A'}</p>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                                <Tooltip
                                                    title={
                                                        !isDoActiveButtonDisabled() ? '' :
                                                            credit_status !== 'deactive' ? 'Credit status is not deactive.' :
                                                                ''
                                                    }
                                                    disableHoverListener={!isDoActiveButtonDisabled()}
                                                >
                                                    <span>
                                                        <button
                                                            className="do-active-btn"
                                                            onClick={handleDoActive}
                                                            disabled={isDoActiveButtonDisabled()}
                                                        >
                                                            Do Active
                                                        </button>
                                                    </span>
                                                </Tooltip>
                                                <Tooltip
                                                    title={
                                                        !isDoDeactiveButtonDisabled() ? '' :
                                                            credit_status !== 'active' ? 'Credit status is not active.' :
                                                                credit_active ? 'Credit must not be active.' :
                                                                    credit !== 0 ? 'Credit must be 0.' :
                                                                        credit_deposit !== 0 ? 'Credit deposit must be 0.' :
                                                                            ''
                                                    }
                                                    disableHoverListener={!isDoDeactiveButtonDisabled()}
                                                >
                                                    <span>
                                                        <button
                                                            className="do-deactive-btn"
                                                            onClick={handleDoDeactive}
                                                            disabled={isDoDeactiveButtonDisabled()}
                                                        >
                                                            Do Deactive
                                                        </button>
                                                    </span>
                                                </Tooltip>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                                <Tooltip
                                                    title={
                                                        !isRemoveCreditButtonDisabled() ? '' :
                                                            !credit_active ? 'Credit is not active.' :
                                                                credit_status !== 'active' ? 'Credit status must be active.' :
                                                                    ''
                                                    }
                                                    disableHoverListener={!isRemoveCreditButtonDisabled()}
                                                >
                                                    <span>
                                                        <button
                                                            className="remove-credit-btn"
                                                            onClick={handleRemoveCredit}
                                                            disabled={isRemoveCreditButtonDisabled()}
                                                        >
                                                            Remove Credit
                                                        </button>
                                                    </span>
                                                </Tooltip>
                                            </div>
                                            <p><strong>Has Future Profile:</strong> {has_future_profile !== null ? (has_future_profile ? 'Yes' : 'No') : 'N/A'}</p>
                                            <p><strong>Future Permission:</strong> {future_permission !== null ? (future_permission ? 'Yes' : 'No') : 'N/A'}</p>
                                        </div>
                                    </>
                                ) : (
                                    <div className="withdraw-form-element-amount-error">This account does not have permission to perform credit operations.</div>
                                )}
                            </>
                        ) : (
                            <Loading />
                        )}
                    </>
                ) : (
                    customerInfo === "User not found" ?
                        <div className="no-user">
                            <i className="fa-solid fa-user-slash"></i>
                            <div>User not found</div>
                        </div>
                        : <Loading />
                )}
            </div>

            {/* Credit Dialog */}
            <Dialog header="Add Credit" visible={showCreditDialog} style={{ width: '30vw' }} onHide={() => setShowCreditDialog(false)}>
                <div className="credit-dialog">
                    <div className="form-group">
                        <label htmlFor="credit">Credit</label>
                        <input
                            type="number"
                            id="credit"
                            value={newCredit}
                            onChange={(e) => setNewCredit(e.target.value)}
                            placeholder="Enter credit amount"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="creditDeposit">Credit Deposit</label>
                        <input
                            type="number"
                            id="creditDeposit"
                            value={newCreditDeposit}
                            onChange={(e) => setNewCreditDeposit(e.target.value)}
                            placeholder="Enter credit deposit"
                            className="form-control"
                        />
                    </div>
                    <button className="update-credit-btn" onClick={handleUpdateCredit}>Update</button>
                </div>
            </Dialog>

            <ConfirmDialog />
        </div>
    );


}

export default CreditFeaturesChange;