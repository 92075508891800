import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MaterialReactTable } from 'material-react-table';
import Loading from "../components/Global/Loading";
import FutureProcessEvents from "./FutureProcessEvents";
import "./Styles/page_profile.css";
import Tooltip from '@mui/material/Tooltip';
import isEqual from "react-fast-compare";
import withLoadingData from "../Container/withLoadingData";
import { onActiveProcessesOpen, onActiveProcessesClose } from "../Reducer/kpriceReducer";
import Decimal from 'decimal.js';
import { debounce } from 'lodash';
import { editNumber_reducing_trailing_zeros, editNumber_reducing_decimal_places, editNumber_increasing_decimal_places, editNumber_separate_with_comma, editNumber_control_decimal_places_length } from '../Lib/calculationFunctions';

const ActiveFutureProcesses = ({ isFutureProcessesLoading }) => {

    const dispatch = useDispatch();

    const productsMiniTickerData = useSelector((state) => state.kprice.productsMiniTicker.data);
    const futureProcesses = useSelector((state) => state.kprice.futureProcesses.data);
    const productsData = useSelector((state) => state.kprice.products.data);
    const currenciesData = useSelector((state) => state.kprice.currencies.data);
    const showPlacesUSDT = currenciesData && currenciesData["USDT"] && currenciesData["USDT"].show_places ? currenciesData["USDT"].show_places : null;

    const tableArea = useRef(null);//tablo alanı

    useEffect(() => {//animasyon için ve verilerin çekilmesi için
        if(tableArea && tableArea.current){
            tableArea.current.style.animation = "none";
            let tempForAnimation = tableArea.current.offsetHeight;
            tableArea.current.style.animation = "open-animation 1s";
        }

        dispatch(onActiveProcessesOpen());

        return () => {//component kapanırken abonelik sonlandırılıyor
            dispatch(onActiveProcessesClose());
        };
    }, []);

    const ItemMarket = ({ product_id }) => {
        const currency = product_id.split("-")[0];
        if(currenciesData && currenciesData[currency] && currenciesData[currency].picture_url){
            return <div className="market"><img src={currenciesData[currency].picture_url} /><span>{product_id}</span></div>;
        }
        return <div className="market"><span>{product_id}</span></div>;
    }

    const ItemPrice = ({ product_id, value }) => {
        const show_places = productsData && productsData[product_id] && productsData[product_id].max_order_asking_price_places ? productsData[product_id].max_order_asking_price_places : null;
        
        if(value == null || value == "" || value == undefined){
            return "";
        } else{
            const response = editNumber_control_decimal_places_length(editNumber_reducing_trailing_zeros(value), show_places);
            if(response){
                return editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(value), show_places), show_places));
            }
            else{
                return editNumber_separate_with_comma(editNumber_reducing_trailing_zeros(value));
            }
        }
    }

    const ItemFutureType = ({ type }) => {
        if(type === "buy"){
            return <span className="success">Buy</span>;
        } else{
            return <span className="danger">Sell</span>;
        }
    }

    const processesDataControl_PriceForTable = (item) => {
        const productMaxOrderAskingPricePlaces = productsData && productsData[item.product_id] && productsData[item.product_id].max_order_asking_price_places ? productsData[item.product_id].max_order_asking_price_places : null;
        const productShowPlaces = productsData && productsData[item.product_id] && productsData[item.product_id].show_places ? productsData[item.product_id].show_places : null;
        
        let firstPrice = null;//item.entry_price
        let secondPrice = null;//productsMiniTickerData[item.product_id].currentPrice

        const response_firstPrice = editNumber_control_decimal_places_length(editNumber_reducing_trailing_zeros(item.entry_price), productMaxOrderAskingPricePlaces);
        if(response_firstPrice){
            firstPrice = editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(item.entry_price), productMaxOrderAskingPricePlaces), productMaxOrderAskingPricePlaces));
        }
        else{
            firstPrice = editNumber_separate_with_comma(editNumber_reducing_trailing_zeros(item.entry_price));
        }

        const response_secondPrice = productsMiniTickerData[item.product_id] && productsMiniTickerData[item.product_id].currentPrice ? editNumber_control_decimal_places_length(editNumber_reducing_trailing_zeros(productsMiniTickerData[item.product_id].currentPrice), productShowPlaces) : null;
        if(response_secondPrice){
            secondPrice = productsMiniTickerData[item.product_id] && productsMiniTickerData[item.product_id].currentPrice ? editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(productsMiniTickerData[item.product_id].currentPrice), productShowPlaces), productShowPlaces)) : null;
        }
        else{
            secondPrice = productsMiniTickerData[item.product_id] && productsMiniTickerData[item.product_id].currentPrice ? editNumber_separate_with_comma(editNumber_reducing_trailing_zeros(productsMiniTickerData[item.product_id].currentPrice)) : null;
        }
        return <span className="priceForTable">{firstPrice}<i className="fa-solid fa-arrow-right"></i>{secondPrice}</span>
    }

    const processesDataControl_CalcProfit = (future_type, product_id, entry_price, available_count) => {
        let profit = 0;

        if(future_type == "buy"){
            profit = (new Decimal(productsMiniTickerData[product_id] ? productsMiniTickerData[product_id].currentPrice : 0).minus(new Decimal(entry_price))).times(new Decimal(available_count));
        }
        else if(future_type == "sell"){
            profit = (new Decimal(entry_price).minus(new Decimal(productsMiniTickerData[product_id] ? productsMiniTickerData[product_id].currentPrice : 0))).times(new Decimal(available_count));
        }
        
        return Number(profit);
    }
    
    const processesDataControl_ProfitForTable = (item) => {
        const currentProfit = item.state == "active" ? processesDataControl_CalcProfit(item.future_type, item.product_id, item.entry_price, item.available_count) : item.process_profit;
        return <span className={currentProfit > 0 ? "success" : (currentProfit < 0 ? "danger" : undefined)}>{editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(currentProfit, showPlacesUSDT), showPlacesUSDT))}</span>
    }

    const tableProcessesColumns = useMemo(() => [//tablo başlıkları
        { accessorKey: 'process_timestamp', header: 'Timestamp' },
        { accessorKey: 'customer_id', header: 'Customer ID', Cell: ({ row }) => row.original.customerIdForTable },
        { accessorKey: 'market', header: 'Market', Cell: ({ row }) => row.original.marketForTable },
        { accessorKey: 'future_type', header: 'Type', Cell: ({ row }) => row.original.futureTypeForTable },
        { accessorKey: 'lot', header: 'Lot', Cell: ({ row }) => row.original.lotForTable },
        { accessorKey: 'price', header: 'Price', Cell: ({ row }) => row.original.priceForTable },
        { accessorKey: 'profit', header: 'Profit', Cell: ({ row }) => row.original.profitForTable },
    ], []);

    const tableProcessesData = useMemo(() => {//tablo bilgileri
        const newData = [];

        productsMiniTickerData && currenciesData && productsData && futureProcesses && futureProcesses.process_items && futureProcesses.process_items.map((history) => {
            newData.push({
                customer_id: history.customer_id ? history.customer_id : "",
                customerIdForTable: 
                    <>
                        <div className="td-text-with-button customer-id">{history.customer_id}</div>
                        <Tooltip title="Profile" className="detail-button" placement="right">
                            <a href={`/profile?customer_id=${history.customer_id}`} target="_blank">
                                <i className="fa-solid fa-user"></i>
                            </a>
                        </Tooltip>
                    </>,
                market: history.product_id,
                marketForTable: <ItemMarket product_id={history.product_id} />,
                leverage: history.leverage ? Number(history.leverage) : 0,
                stopLossForTable: <ItemPrice product_id={history.product_id} value={history.stop_loss} />,
                takeProfitForTable: <ItemPrice product_id={history.product_id} value={history.take_profit} />,
                process_timestamp: history.result_timestamp ? history.result_timestamp : history.entry_timestamp,
                process_order_id: history.order_id ? history.order_id : "",
                process_entry_date: history.entry_date ? history.entry_date : "",
                process_result_date: history.result_date ? history.result_date : "",
                future_type: history.future_type ? history.future_type : "",
                futureTypeForTable: history.future_type ? <ItemFutureType type={history.future_type} /> : "",
                lot: history.available_count ? history.available_count : 0,
                lotForTable: editNumber_separate_with_comma(editNumber_reducing_trailing_zeros(history.available_count)),
                price: history.entry_price ? history.entry_price : 0,
                priceForTable: processesDataControl_PriceForTable(history),
                profit: processesDataControl_CalcProfit(history.future_type, history.product_id, history.entry_price, history.available_count),
                profitForTable: processesDataControl_ProfitForTable(history),
            })
        })

        return newData;
    }, [futureProcesses, currenciesData, productsData]);

    const handleRowClick = (event) => {//alt alan için yapılan dinlemenin tetiklediği fonksiyon
        let row = null;
        let rowIsValid = false;
        if(event.target.tagName == "TR"){
            rowIsValid = true;
            row = event.target;
        }
        else{
            rowIsValid = true;
            row = event.target.closest('tr');
        }
        
        if(row && rowIsValid){
            const expandButton = row.querySelector("td:first-child button");
            if(expandButton){
                expandButton.click();
            }
        }
    };

    useEffect(() => {//tabloyu listener ile dinleme alanı (alt alanın açılması ile ilgili)
        const container = document.querySelector('.withExpandMenu');

        if (container) {
            container.addEventListener('click', handleRowClick);
      
            return () => {
                container.removeEventListener('click', handleRowClick);
            };
        }
    }, [tableProcessesData]);

    useEffect(() => {//tabloyu observer ile dinleme alanı (scroll ile ilgili)
        const tableContainer = document.querySelector('.active-future-processes .withExpandMenu');
        if (!tableContainer) return;

        let allMutations = []; // Tüm mutationları biriktireceğimiz array

        // Tüm mutationları işleyecek fonksiyon
        const handleMutations = debounce(() => {
            let hasDetailPanel = false;
            for (const mutation of allMutations) {
                if(mutation){
                    if(mutation.target.classList.contains("Mui-TableBodyCell-DetailPanel") || mutation.target.classList.contains("submenu-process-values-right") || mutation.target.classList.contains("MuiTouchRipple-root")){
                        hasDetailPanel = true;
                    }
                }
            }

            if(!hasDetailPanel){
                const submenuElements = document.querySelectorAll('.submenu-values');
                submenuElements.forEach(element => {
                    const closestTrElement = element.closest('tr');
                    const closestTrElementPrevious = closestTrElement.previousElementSibling;
                    if(closestTrElementPrevious){
                        const elementExpandButton = closestTrElementPrevious.querySelector("td:first-child button");
                        if(elementExpandButton){
                            elementExpandButton.click();
                        }
                    }
                });
            }
            allMutations = []; // İşlemden sonra array'i sıfırla
        }, 100);

        // Observer callback fonksiyonu, her bir mutation'ı biriktirir
        const observerCallback = (mutationsList, observer) => {
            allMutations = allMutations.concat(mutationsList); // Mevcut mutationları biriktir
            handleMutations(); // Debounce fonksiyonunu tetikle
        };

        const observer = new MutationObserver(observerCallback);
        observer.observe(tableContainer, { childList: true, subtree: true });

        return () => {
            observer.disconnect();
            handleMutations.cancel(); // Debounce fonksiyonunu iptal et
        };
    }, [tableProcessesData]);
    
    return (
        <div id="main" className="main">
            <div className="allsections allsections-border">
                <div className="table-area active-future-processes" ref={tableArea}>
                    <div className={isFutureProcessesLoading != false ? "list loading" : "list"}>
                        {isFutureProcessesLoading != false ? 
                            <Loading />
                            :
                            <div className="column6 withExpandMenu noPagination rowVirtualization">
                                <MaterialReactTable columns={tableProcessesColumns} data={tableProcessesData} enablePagination={false} enableRowVirtualization={true} initialState={{columnVisibility: { process_timestamp: false }, sorting: [{id: 'process_timestamp', desc: true}]}}
                                    renderDetailPanel={({ row }) => (
                                        <div className="submenu-values process">
                                            <div className="submenu-process-values-left" data-orderid={row.original.process_order_id}>
                                                <div>
                                                    <span>Date:</span>
                                                    <label>{row.original.process_entry_date}</label>
                                                </div>
                                                <div>
                                                    <span>Leverage:</span>
                                                    <label>{row.original.leverage}</label>
                                                </div>
                                                <div className="take_profit">
                                                    <span>Take Profit:</span>
                                                    <label>{row.original.takeProfitForTable}</label>
                                                </div>
                                                <div className="stop_loss">
                                                    <span>Stop Loss:</span>
                                                    <label>{row.original.stopLossForTable}</label>
                                                </div>
                                            </div>
                                            <div className="submenu-process-values-right">
                                                <FutureProcessEvents orderId={row.original.process_order_id} customerId={row.original.customer_id} />
                                            </div>
                                        </div>
                                    )}
                                    />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
    
}

export default withLoadingData()(React.memo(ActiveFutureProcesses, isEqual));