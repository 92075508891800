import React, { useEffect, useState, useMemo, useRef } from "react";
import { MaterialReactTable } from 'material-react-table';
import { useSelector } from "react-redux";
import { onChangeUsersType } from "../Reducer/kpriceReducer";
import { useDispatch } from "react-redux";
import Tooltip from '@mui/material/Tooltip';
import withLoadingData from "../Container/withLoadingData";
import Loading from "../components/Global/Loading";
import isEqual from "react-fast-compare";
import { editNumber_reducing_trailing_zeros, editNumber_reducing_decimal_places, editNumber_increasing_decimal_places, editNumber_separate_with_comma } from '../Lib/calculationFunctions';
import { onGeneralListOpen, onGeneralListClose } from "../Reducer/kpriceReducer";
import Decimal from 'decimal.js';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

const Users = ({ isUsersLoading, isFutureProfilesLoading, isFutureProcessesLoading }) => {

    const dispatch = useDispatch();
    const tableArea = useRef(null);//tablo alanı

    const usersData = useSelector((state) => state.kprice.users.data);
    const productsMiniTickerData = useSelector((state) => state.kprice.productsMiniTicker.data);
    const currenciesData = useSelector((state) => state.kprice.currencies.data);
    const walletData = useSelector((state) => state.kprice.wallet.data);
    const futureProfiles = useSelector((state) => state.kprice.futureProfiles.data);
    const futureProcesses = useSelector((state) => state.kprice.futureProcesses.data);
    const [tableAreaAnimationLock, setTableAreaAnimationLock] = useState(false);
    const [firstLoading, setFirstLoading] = useState(0);
    const [tableLoading, setTableLoading] = useState(true);

    useEffect(() => {//animasyon için - type değiştikçe tetiklenecek
        if(tableArea && tableArea.current && !tableAreaAnimationLock){
            setTableAreaAnimationLock(true);
            tableArea.current.style.animation = "none";
            let tempForAnimation = tableArea.current.offsetHeight;
            tableArea.current.style.animation = "open-animation 1s";
        }

        dispatch(onGeneralListOpen());

        return () => {//component kapanırken abonelik sonlandırılıyor
            dispatch(onGeneralListClose());
        };
    }, []);

    useEffect(() => {//animasyon için - type değiştikçe tetiklenecek
        if(firstLoading >= 2){
            if(isUsersLoading == false && isFutureProfilesLoading == false && isFutureProcessesLoading == false){
                setTableLoading(false);
            }
            else{
                setTableLoading(true);
            }
        }
        else if(firstLoading == 0){
            setFirstLoading(1);
        }
        else if(firstLoading == 1){
            setFirstLoading(2);
        }
    }, [isUsersLoading, isFutureProfilesLoading, isFutureProcessesLoading]);

    const tableColumns = useMemo(() => [//users tablo başlıkları
        { accessorKey: 'customer_id', header: 'Customer ID', Cell: ({ row }) => row.original.customerIdForTable },
        { accessorKey: 'full_name', header: 'Full Name' },
        { accessorKey: 'status_level', header: 'Status Level' },
        { accessorKey: 'spot_wallet', header: 'Spot Wallet', Cell: ({ row }) => row.original.spotWalletForTable },
        { accessorKey: 'future_balance', header: 'Future Balance', Cell: ({ row }) => row.original.futureBalanceForTable },
        { accessorKey: 'credit', header: 'Credit', Cell: ({ row }) => row.original.creditForTable },
        { accessorKey: 'total_surety', header: 'Total Surety', Cell: ({ row }) => row.original.totalSuretyForTable },
        { accessorKey: 'total_assets', header: 'Total Assets', Cell: ({ row }) => row.original.totalAssetsForTable },
        { accessorKey: 'free_margin', header: 'Free Margin', Cell: ({ row }) => row.original.freeMarginForTable },
        { accessorKey: 'margin_level', header: 'Margin Level', Cell: ({ row }) => row.original.marginLevelForTable },
        { accessorKey: 'total_profit', header: 'Total Profit', Cell: ({ row }) => row.original.totalProfitForTable },
    ], []);

    const tableData = useMemo(() => {//users tablo bilgileri
        if (!usersData.length) return [];
        
        let priceShowPlaces = currenciesData && currenciesData["USDT"] && currenciesData["USDT"].show_places ? currenciesData["USDT"].show_places : null;
        const newData = [];
        usersData.map((item, index) => {
            let spotWallet = new Decimal(0);
            let spotWalletForTable = "≈ $ 0";
            let tempTotalBalance = 0;
            let tempTotalProfit = 0;
            let tempTotalProfitClass = "";
            let tempTotalSurety = 0;
            let tempTotalAssets = 0;
            let tempCredit = 0;
            let tempFreeMargin = 0;
            let tempMarginLevel = 0;
            let tempMarginLevelClass = "";
            let creditValue = 0;
            let profilValues = null;
            
            //spot_wallet değeri
            if(productsMiniTickerData && walletData && walletData.length > 0){
                walletData.filter(x => x.customer_id == item.customer_id).map((walletItem) => {
                    if(walletItem.currency == "USDT"){
                        spotWallet = Number(new Decimal(spotWallet).plus(walletItem.total_count).toString());
                    }
                    else{
                        if(productsMiniTickerData && productsMiniTickerData[walletItem.currency+"-USDT"] && productsMiniTickerData[walletItem.currency+"-USDT"].currentPrice){
                            let currentCount = new Decimal(productsMiniTickerData[walletItem.currency+"-USDT"].currentPrice).times(new Decimal(walletItem.total_count));
                            spotWallet = Number(new Decimal(spotWallet).plus(currentCount).toString());
                        }
                    }
                });

                spotWalletForTable = editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(spotWallet.toString()), priceShowPlaces), priceShowPlaces))
            }

            //future değerleri
            if(productsMiniTickerData && futureProcesses && futureProcesses.process_items && futureProfiles && futureProfiles.profile_items){
                profilValues = futureProfiles.profile_items.find(x => x.customer_id == item.customer_id);
                creditValue = profilValues && profilValues.credit ? profilValues.credit : 0;
                tempTotalBalance = profilValues.balance ? profilValues.balance : 0;
                tempTotalProfit = 0;
                tempTotalSurety = 0;
                tempTotalAssets = 0;
                tempCredit = profilValues.credit ? profilValues.credit : 0;
                tempFreeMargin = 0;
                tempMarginLevel = 0;
                
                futureProcesses.process_items.filter(x => x.customer_id == item.customer_id).map((process) => {
                    if(process.future_type == "buy"){
                        tempTotalProfit = new Decimal(tempTotalProfit).plus((new Decimal(productsMiniTickerData[process.product_id].currentPrice).minus(new Decimal(process.entry_price))).times(new Decimal(process.available_count)));
                    }
                    else if(process.future_type == "sell"){
                        tempTotalProfit = new Decimal(tempTotalProfit).plus((new Decimal(process.entry_price).minus(new Decimal(productsMiniTickerData[process.product_id].currentPrice))).times(new Decimal(process.available_count)));
                    }
                    
                    tempTotalSurety = new Decimal(tempTotalSurety).plus(new Decimal(process.available_surety));
                });
                tempTotalAssets = new Decimal(tempTotalBalance).plus(new Decimal(tempTotalProfit));
                tempTotalAssets = tempTotalAssets.plus(new Decimal(tempCredit));
    
                tempFreeMargin = tempTotalAssets ? new Decimal(tempTotalAssets).minus(new Decimal(tempTotalSurety)) : 0;
                tempMarginLevel = futureProcesses.process_items.filter(x => x.customer_id == item.customer_id).length > 0 ? (tempTotalAssets && tempTotalSurety ? (new Decimal(tempTotalAssets).div(new Decimal(tempTotalSurety))).times(100).toString() : 0) : null;
                
                if(Number(tempTotalProfit.toString()) > 0){
                    tempTotalProfitClass = "success";
                }
                else if(Number(tempTotalProfit.toString()) < 0){
                    tempTotalProfitClass = "danger";
                }

                if(Number(tempMarginLevel) < 75){
                    tempMarginLevelClass = "danger";
                }
            }

            newData.push({
                customer_id: item.customer_id ? item.customer_id : 0,
                customerIdForTable: item.customer_id ? 
                    <>
                        <div className="td-text-with-button customer-id">{item.customer_id}</div>
                        <Tooltip title="Profile" className="detail-button" placement="top">
                            <a href={`/profile?customer_id=${item.customer_id}`} target="_blank">
                                <i className="fa-solid fa-user"></i>
                            </a>
                        </Tooltip>
                        <Tooltip title="Future" className="detail-button" placement="top">
                            <a href={`/profile/future?customer_id=${item.customer_id}`} target="_blank">
                                <i className="fa-solid fa-chart-line"></i>
                            </a>
                        </Tooltip>
                    </> : "",
                full_name: item.full_name ? item.full_name : "",
                status_level: item.status_level ? item.status_level : 0,
                spot_wallet: spotWallet ? spotWallet : 0,
                spotWalletForTable: spotWalletForTable ? `≈ $ ${spotWalletForTable}` : "",
                future_balance: tempTotalBalance ? Number(tempTotalBalance.toString()) : 0,
                futureBalanceForTable: editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(tempTotalBalance), priceShowPlaces), priceShowPlaces)),
                credit: tempCredit ? tempCredit : 0,
                creditForTable: item.customer_id ? 
                    <>
                        <div className="total-value">
                            {editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(creditValue), priceShowPlaces), priceShowPlaces))}
                            <Tooltip title={<>
                                <div><b>Credit Active: </b>{profilValues && profilValues.credit_active ? "true" : "false"}</div>
                                <div><b>Credit Available: </b>{profilValues && profilValues.credit_available ? "true" : "false"}</div>
                                <div><b>Credit Deposit: </b>{profilValues && profilValues.credit_deposit ? profilValues.credit_deposit : 0}</div>
                                <div><b>Credit Status: </b>{profilValues && profilValues.credit_status ? profilValues.credit_status : "deactive"}</div>
                            </>} placement="right">
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </> : "",
                total_surety: tempTotalSurety ? Number(tempTotalSurety.toString()) : 0,
                totalSuretyForTable: editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(tempTotalSurety.toString()), priceShowPlaces), priceShowPlaces)),
                total_assets: tempTotalAssets ? Number(tempTotalAssets.toString()) : 0,
                totalAssetsForTable: editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(tempTotalAssets.toString()), priceShowPlaces), priceShowPlaces)),
                free_margin: tempFreeMargin ? Number(tempFreeMargin.toString()) : 0,
                freeMarginForTable: editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(tempFreeMargin.toString()), priceShowPlaces), priceShowPlaces)),
                margin_level: tempMarginLevel ? Number(tempMarginLevel.toString()) : 999999,
                marginLevelForTable: tempMarginLevel ? <div className={tempMarginLevelClass}>{editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(tempMarginLevel.toString()), priceShowPlaces), priceShowPlaces))}</div> : "-",
                total_profit: tempTotalProfit ? Number(tempTotalProfit.toString()) : "",
                totalProfitForTable: <div className={tempTotalProfitClass}>{editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(tempTotalProfit.toString()), priceShowPlaces), priceShowPlaces))}</div>,
            });
        });
        
        return newData;
    }, [usersData, currenciesData, productsMiniTickerData, walletData, futureProfiles, futureProcesses]);

    return (
        <div className="main">
            <div className="allsections allsections-border table-area">
                <div ref={tableArea} className={tableLoading ? "list loading" : "list"}>
                    {tableLoading ?
                        <Loading /> :
                        <div className="column11 noPagination rowVirtualization bigTable">
                            <MaterialReactTable columns={tableColumns} data={tableData} enablePagination={false} enableRowVirtualization={true} initialState={{sorting: [{id: 'spot_wallet', desc: true}]}} />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default withLoadingData()(React.memo(Users, isEqual));