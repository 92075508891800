import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { getPriceForWalletCurrencies, getPriceForCurrency } from '../Lib/calculationFunctions';
import { editNumber_reducing_trailing_zeros, editNumber_reducing_trailing_zeros_special, editNumber_reducing_decimal_places, editNumber_increasing_decimal_places, editNumber_separate_with_comma } from '../Lib/calculationFunctions';
import withLoadingData from "../Container/withLoadingData";
import Loading from "../components/Global/Loading";
import isEqual from "react-fast-compare";
import { MaterialReactTable } from 'material-react-table';

const ProfileWalletList = ({ isProductsLoading, walletData, showAllCount }) => {

    const [walletList, setWalletList] = useState({});//liste verileri
    const currenciesData = useSelector((state) => state.kprice.currencies.data);
    const productsData = useSelector((state) => state.kprice.products.data);
    const productsMiniTickerData = useSelector((state) => state.kprice.productsMiniTicker.data);
    const walletDataKeys = walletData ? Object.keys(walletData) : [];
    const [tableData, setTableData] = useState(null);//tablo verileri

    useEffect(() => {
        const processData = () => {
            const newData = {};

            if(currenciesData && productsMiniTickerData && walletData){
                walletDataKeys.forEach((item) => {
                    if(walletData[item] && walletData[item].count && walletData[item].count != "NaN" && walletData[item].count != "0"){
                        let itemprice = getPriceForWalletCurrencies(item, walletData, productsMiniTickerData);
                        let currentPrice = getPriceForCurrency(item, productsMiniTickerData);
                        newData[item] = {
                            picture_url: currenciesData[item] && currenciesData[item].picture_url ? currenciesData[item].picture_url : undefined,
                            name: currenciesData[item] && currenciesData[item].currency_name ? currenciesData[item].currency_name : "NaN",
                            currency: walletData[item] && walletData[item].currency ? walletData[item].currency : "NaN",
                            count: walletData[item] && walletData[item].count ? walletData[item].count : "NaN",
                            price: itemprice,
                            currentPrice: currentPrice,
                            currentPriceShowPlaces: productsData[`${item}-USDT`] && productsData[`${item}-USDT`].show_places ? productsData[`${item}-USDT`].show_places : "NaN"
                        };
                    }
                });
            }
            
            return newData;
        };
        
        setWalletList(processData());
    }, [currenciesData, productsMiniTickerData, walletData]);

    useEffect(() => {
        const newData = [];

        if(currenciesData){
            Object.keys(walletList).forEach((item) => {
                newData.push({
                    info: tableData_info(walletList[item]),
                    sort: Number(walletList[item].price.toString()),
                });
            })
        }
        
        setTableData(newData);
    }, [walletList, currenciesData, showAllCount]);

    const columnsData = useMemo(() => [//tablo bilgileri
        { accessorKey: 'info', header: 'Info' },
        { accessorKey: 'sort', header: 'Sort', show: false },
    ], []);

    const tableData_info = (item) => {
        return <div className="section-walletlist-element">
                    <div className="section-walletlist-left">
                        <img src={item.picture_url} />
                        <div>
                            <label>{item.name}</label>
                            <span>{item.currency}</span>
                        </div>
                    </div>
                    <div className="section-walletlist-right">
                        {showAllCount ? 
                            <label>{editNumber_separate_with_comma(item.count)}</label>
                        : 
                            <label>{currenciesData && currenciesData[item.currency] && currenciesData[item.currency].show_places && editNumber_separate_with_comma(editNumber_reducing_trailing_zeros_special(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(item.count, currenciesData[item.currency].show_places), currenciesData[item.currency].show_places)))}</label>
                        }
                        <span>{item.price !== "NaN" ? `≈ $ ${currenciesData && currenciesData["USDT"] && currenciesData["USDT"].show_places ? editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(item.price.toString()), currenciesData["USDT"].show_places), currenciesData["USDT"].show_places)) : ""}` : ''}</span>
                    </div>
                </div>;
    }

    return(
        <div id="section-walletlist" className="allsections allsections-border scrollbarhide section-walletlist">
            {!walletData || isProductsLoading != false ? 
                <Loading /> : 
                (Object.keys(walletList).length > 0 ? 
                    <div className="section-walletlist-table">
                        <MaterialReactTable 
                            columns={columnsData} 
                            data={tableData} 
                            enablePagination={false}
                            enableRowVirtualization={true}
                            initialState={{
                                columnVisibility: { sort: false },
                                sorting: [{id: 'sort', desc: true}]
                            }} 
                        />
                    </div> :
                    <div className="no-balance">
                        <i className="fa-solid fa-wallet"></i>
                        <span>No Balance</span>
                    </div>
                )
            }
        </div>
    )
    
}

export default withLoadingData()(React.memo(ProfileWalletList, isEqual));