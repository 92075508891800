import React, { useEffect, useState, useMemo, useRef } from "react";
import { MaterialReactTable } from 'material-react-table';
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from 'react-router-dom';
import withLoadingData from "../Container/withLoadingData";
import { editNumber_reducing_trailing_zeros, editNumber_reducing_decimal_places, editNumber_increasing_decimal_places, editNumber_separate_with_comma } from '../Lib/calculationFunctions';
import Loading from "../components/Global/Loading";
import isEqual from "react-fast-compare";
import axios from "axios";
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import Decimal from 'decimal.js';
import Tooltip from '@mui/material/Tooltip';
import { debounce } from 'lodash';
import SpotFutureHistory from "./SpotFutureHistory";

const Spot = ({ isSpotLoading }) => {

    const location = useLocation();
    const query = useQuery();
    const navigate = useNavigate();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const [pageType, setPageType] = useState(null);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [currencyData, setCurrencyData] = useState(null);
    const tableArea = useRef(null);//tablo alanı
    const [submenuTabValue, setSubmenuTabValue] = useState([]);

    const spotData = useSelector((state) => state.kprice.spot.data);
    const productsMiniTickerData = useSelector((state) => state.kprice.productsMiniTicker.data);
    const currenciesData = useSelector((state) => state.kprice.currencies.data);

    useEffect(() => {//animasyon için - type değiştikçe tetiklenecek
        if(tableArea && tableArea.current){
            tableArea.current.style.animation = "none";
            let tempForAnimation = tableArea.current.offsetHeight;
            tableArea.current.style.animation = "open-animation 1s";
        }
    }, [location]);

    useEffect(() => {//sayfanın type bilgisi ayarlanıyor
        const currentType = query.get("type");
        const currentCurrency = query.get("currency");
        if(currentType){
            if(currentType == "with_currency" && currentCurrency){
                setPageType("with_currency");
                setSelectedCurrency(currentCurrency);
            }
            else{
                setPageType("all");
            }
        }
        else{
            navigate(`/spot?type=all`);
        }
    }, [query]);

    useEffect(() => {//with_currency için bilgiler çekiliyor
        if(pageType == "with_currency" && selectedCurrency){
            getCurrencyInfo();
        }
    }, [pageType, selectedCurrency]);

    useEffect(() => {
        setSubmenuTabValue([]);
    }, [pageType]);

    const getCurrencyInfo = async () => {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        try {
            const response = await axios.get(`https://operationapi.kpriceapp.com/wallet?type=with_currency&currency=${selectedCurrency}`, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });
            
            if(response.status == "200"){
                if(response.data && response.data.wallet_items){
                    if(response.data.wallet_items.length > 0){
                        setCurrencyData(response.data.wallet_items);
                    }
                    else{
                        setCurrencyData([]);
                    }
                }
            }
            else{
                toast.error("There was a problem loading currency information!");
            }
        }
        catch (error) {
            toast.error("There was a problem loading currency information!");
        }
    }
    
    const tableColumns = useMemo(() => [//users tablo başlıkları
        { accessorKey: 'currency', header: 'Currency', size: 50 },
        { accessorKey: 'total_count', header: 'Total Count', size: 50 },
        { accessorKey: 'dollar_equivalent', header: 'Dollar Equivalent', size: 50, Cell: ({ row }) => row.original.dollar_equivalentForTable },
        { accessorKey: 'detail', header: 'Detail', size: 50 },
    ], []);

    const tableData = useMemo(() => {//users tablo bilgileri
        const newData = [];

        if(pageType == "all" && spotData && Object.keys(spotData).length > 0){
            Object.keys(spotData).map((key, index) => {
                let currentDollar = key == "USDT" ? spotData[key] : (productsMiniTickerData && productsMiniTickerData[key+"-USDT"] && productsMiniTickerData[key+"-USDT"].currentPrice ? new Decimal(productsMiniTickerData[key+"-USDT"].currentPrice).times(new Decimal(spotData[key])) : 0);
                let formatedDollar = currenciesData && currenciesData["USDT"] && currenciesData["USDT"].show_places ? editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(currentDollar.toString()), currenciesData["USDT"].show_places), currenciesData["USDT"].show_places)) : 0;

                newData.push({
                    index: index,
                    currency: key,
                    total_count: spotData[key],
                    dollar_equivalent: Number(currentDollar.toString()),
                    dollar_equivalentForTable: `≈ $ ${formatedDollar}`,
                    detail: 
                        <a href={`/spot?type=with_currency&currency=${key}`} className="detail-button with-text" target="_blank">
                            <span>Detail</span>
                            <i className="fa-solid fa-coins"></i>
                        </a>
                })
            })
        }

        return newData;
    }, [spotData, pageType, productsMiniTickerData, currenciesData]);

    const tableColumnsCurrency = useMemo(() => [//users tablo başlıkları
        { accessorKey: 'customer_id', header: 'Customer ID', size: 50, Cell: ({ row }) => row.original.customer_idForTable },
        { accessorKey: 'count', header: 'Count', size: 50 },
        { accessorKey: 'dollar_equivalent', header: 'Dollar Equivalent', size: 50, Cell: ({ row }) => row.original.dollar_equivalentForTable },
    ], []);

    const tableDataCurrency = useMemo(() => {//users tablo bilgileri
        const newData = [];

        if(pageType == "with_currency" && currencyData && Object.keys(currencyData).length > 0){
            currencyData.map((item, index) => {
                let currentCount = Number(item.total_count);
                let currentDollar = selectedCurrency == "USDT" ? currentCount : (productsMiniTickerData && productsMiniTickerData[selectedCurrency+"-USDT"] && productsMiniTickerData[selectedCurrency+"-USDT"].currentPrice ? new Decimal(productsMiniTickerData[selectedCurrency+"-USDT"].currentPrice).times(new Decimal(currentCount)) : 0);
                let formatedDollar = currenciesData && currenciesData["USDT"] && currenciesData["USDT"].show_places ? editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(currentDollar.toString()), currenciesData["USDT"].show_places), currenciesData["USDT"].show_places)) : 0;

                newData.push({
                    index: index,
                    count: currentCount,
                    dollar_equivalent: Number(currentDollar.toString()),
                    dollar_equivalentForTable: `≈ $ ${formatedDollar}`,
                    customer_id: item.customer_id,
                    customer_idForTable: 
                        <>
                            <div className="td-text-with-button customer-id">{item.customer_id}</div>
                            <Tooltip title="Profile" className="detail-button" placement="right">
                                <a href={`/profile?customer_id=${item.customer_id}`} target="_blank">
                                    <i className="fa-solid fa-user"></i>
                                </a>
                            </Tooltip>
                        </>
                })
            })
        }

        return newData;
    }, [currencyData, pageType, productsMiniTickerData, currenciesData]);

    const handleRowClick = (event) => {//alt alan için yapılan dinlemenin tetiklediği fonksiyon
            let row = null;
            let rowIsValid = false;
            if(event.target.tagName == "TR"){
                rowIsValid = true;
                row = event.target;
            }
            else{
                rowIsValid = true;
                row = event.target.closest('tr');
            }

            //düzenleme butonu ve alt alanı açan butona tıklatıldıysa dikkate alma, aşağıdaki adımları atla
            if(event.target.tagName == "A" || event.target.tagName == "SPAN" || event.target.tagName == "I"){
                rowIsValid = false;
            }
            
            if(row && rowIsValid){
                const expandButton = row.querySelector("td:first-child button");
                if(expandButton){
                    expandButton.classList.add("clicked");
                    expandButton.click();
                    setTimeout(() => {
                        expandButton.classList.remove("clicked");
                    }, 500);
                }
            }
    };

    useEffect(() => {//tabloyu listener ile dinleme alanı (alt alanın açılması ile ilgili)
        const container = document.querySelector('.withExpandMenu');

        if (container) {
            container.addEventListener('click', handleRowClick);
      
            return () => {
                container.removeEventListener('click', handleRowClick);
            };
        }
    }, [pageType, isSpotLoading]);

    useEffect(() => {//tabloyu observer ile dinleme alanı (scroll ile ilgili)
        const tableContainer = document.querySelector('.withExpandMenu');
        if (!tableContainer) return;

        let allMutations = []; // Tüm mutationları biriktireceğimiz array

        // Tüm mutationları işleyecek fonksiyon
        const handleMutations = debounce(() => {
            let hasDetailPanel = false;
            for (const mutation of allMutations) {
                if(mutation){
                    if(mutation.target.classList.contains("total-value-tooltip") || mutation.target.classList.contains("Mui-TableBodyCell-DetailPanel") || mutation.target.classList.contains("submenu-elements") || mutation.target.classList.contains("scrollable-section-elements") || mutation.target.classList.contains("MuiTouchRipple-root")){
                        hasDetailPanel = true;
                    }
                }
            }

            if(!hasDetailPanel){
                const submenuElements = document.querySelectorAll('.submenu-values');
                submenuElements.forEach(element => {
                    const closestTrElement = element.closest('tr');
                    const closestTrElementPrevious = closestTrElement.previousElementSibling;
                    if(closestTrElementPrevious){
                        const elementExpandButton = closestTrElementPrevious.querySelector("td:first-child button");
                        if(elementExpandButton && !elementExpandButton.classList.contains("clicked")){
                            elementExpandButton.classList.add("clicked");
                            elementExpandButton.click();
                            setTimeout(() => {
                                elementExpandButton.classList.remove("clicked");
                            }, 500);
                        }
                    }
                });
            }
            allMutations = []; // İşlemden sonra array'i sıfırla
        }, 100);

        // Observer callback fonksiyonu, her bir mutation'ı biriktirir
        const observerCallback = (mutationsList, observer) => {
            allMutations = allMutations.concat(mutationsList); // Mevcut mutationları biriktir
            handleMutations(); // Debounce fonksiyonunu tetikle
        };

        const observer = new MutationObserver(observerCallback);
        observer.observe(tableContainer, { childList: true, subtree: true });

        return () => {
            observer.disconnect();
            handleMutations.cancel(); // Debounce fonksiyonunu iptal et
        };
    }, [pageType, tableData, tableDataCurrency]);

    return (
        <div className="main">
            <div className="allsections allsections-border table-area page-spot">
                <div ref={tableArea} className={isSpotLoading != false && currencyData == null ? "list loading" : "list"}>
                    {pageType == "all" &&
                        (isSpotLoading != false ?
                            <Loading /> :
                            <div className="column4 withExpandMenu noPagination rowVirtualization">
                                <MaterialReactTable columns={tableColumns} data={tableData} enablePagination={false} enableRowVirtualization={true} initialState={{sorting: [{id: 'dollar_equivalent', desc: true}]}} 
                                renderDetailPanel={({ row }) => (
                                    <div className="submenu-values">
                                        <div className="submenu-tabs">
                                            <div className={`item ${submenuTabValue[row.original.index] ? (submenuTabValue[row.original.index] != "spending_currency" ? "active" : "") : "active"}`} onClick={() => {let value = [...submenuTabValue];value[row.original.index] = "earning_currency";setSubmenuTabValue(value);}}>Earning Currency</div>
                                            <div className={`item ${submenuTabValue[row.original.index] && submenuTabValue[row.original.index] == "spending_currency" ? "active" : ""}`} onClick={() => {let value = [...submenuTabValue];value[row.original.index] = "spending_currency";setSubmenuTabValue(value);}}>Spending Currency</div>
                                        </div>
                                        <div className="submenu-elements">
                                            <SpotFutureHistory fromPage="spot-all" dataType={submenuTabValue[row.original.index] ? submenuTabValue[row.original.index] : "earning_currency"} dataCurrency={row.original.currency} dataCustomerId={null} />
                                        </div>
                                    </div>
                                )}
                                />
                            </div>
                        )
                    }
                    {pageType == "with_currency" &&
                        (currencyData ? 
                            <div className="column3 withExpandMenu noPagination rowVirtualization">
                                <div className="spot-currency-info"><i className="fa-solid fa-coins"></i>{selectedCurrency}</div>
                                <MaterialReactTable columns={tableColumnsCurrency} data={tableDataCurrency} enablePagination={false} enableRowVirtualization={true} initialState={{sorting: [{id: 'dollar_equivalent', desc: true}]}} 
                                renderDetailPanel={({ row }) => (
                                    <div className="submenu-values">
                                        <div className="submenu-tabs">
                                            <div className={`item ${submenuTabValue[row.original.index] ? (submenuTabValue[row.original.index] != "spending_currency" ? "active" : "") : "active"}`} onClick={() => {let value = [...submenuTabValue];value[row.original.index] = "earning_currency";setSubmenuTabValue(value);}}>Earning Currency</div>
                                            <div className={`item ${submenuTabValue[row.original.index] && submenuTabValue[row.original.index] == "spending_currency" ? "active" : ""}`} onClick={() => {let value = [...submenuTabValue];value[row.original.index] = "spending_currency";setSubmenuTabValue(value);}}>Spending Currency</div>
                                        </div>
                                        <div className="submenu-elements">
                                            <SpotFutureHistory fromPage="spot-currency" dataType={submenuTabValue[row.original.index] ? submenuTabValue[row.original.index] : "earning_currency"} dataCurrency={selectedCurrency} dataCustomerId={row.original.customer_id} />
                                        </div>
                                    </div>
                                )}
                                />
                            </div> 
                            : <Loading />
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default withLoadingData()(React.memo(Spot, isEqual));