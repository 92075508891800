import moment from "moment-timezone";
import * as d3 from "d3";
import { setDataWithLastValues, calcPriceAndPercentageDifference } from './calculationFunctions';
import Decimal from 'decimal.js';

const dateFormat = d3.timeParse("%Y-%m-%d %H:%M");

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

const timestampToDatetime = (timeType, timeCount, timestamp) => {
  switch (timeType) {
    case "minute":
    case "minutes":
      return (
        moment(timestamp)
          .minute(
            Math.floor(moment(timestamp).minute() / timeCount) * timeCount
          )
          .second(0)
          .format("YYYY-MM-DD HH:mm")
      );
    case "hour":
    case "hours":
      return (
        moment(timestamp)
          .hour(Math.floor(moment(timestamp).hour() / timeCount) * timeCount)
          .minute(0)
          .second(0)
          .format("YYYY-MM-DD HH:mm")
      );
    case "day":
    case "days":
      return moment(timestamp)
        .hour(9)
        .minute(0)
        .second(0)
        .format("YYYY-MM-DD HH:mm");
    case "week":
    case "weeks":
      return moment(timestamp)
        .startOf('isoWeek')
        .format("YYYY-MM-DD HH:mm");
    case "month":
    case "months":
      return moment(timestamp)
        .startOf('month')
        .format("YYYY-MM-DD HH:mm");
    case "year":
    case "years":
      return moment(timestamp)
        .startOf('year')
        .format("YYYY-MM-DD HH:mm");
    default:
      return undefined;
  }
};

const productsDataUtils = {
  init: (products, state) => {
    //console.log("productsDataUtils - init");
    //console.log(products);
    const productsNewData = products.products;
    const productsVariables = products.variables;
    const productsCurrencies = products.currencies;
    let spot_commission_rate = null;
    let currency_max_before_places = null;
    let future_commission_rate = null;
    const tempProductsData = {};
    const tempCurrenciesData = {};
    const tempProductsMiniTickerData = {};

    productsVariables.forEach((i) => {
      if(i.name == "spot_commission_rate"){
        spot_commission_rate = i.value;
      }
      else if(i.name == "currency_max_before_places"){
        currency_max_before_places = i.value;
      }
      else if(i.name == "future_commission_rate"){
        future_commission_rate = i.value;
      }
    });

    productsNewData.forEach((item) => {
      //fiyat farkını ve yüzdesini hesaplama işlemleri
      const [price_difference, percentage_difference] = calcPriceAndPercentageDifference(item["24h_o"], item["24h_c"]);

      tempProductsData[item.product_id] = {
        currentPrice: item.price,
        previousCurrentPrice: null,
        currentPriceTimestamp: Date.now(),
        currentPriceLastFirstTradeId: null,
        price_difference_24h: price_difference,
        percentage_difference_24h: percentage_difference,
        c_24h: item["24h_c"],
        h_24h: item["24h_h"],
        l_24h: item["24h_l"],
        o_24h: item["24h_o"],
        q_24h: item["24h_q"],
        v_24h: item["24h_v"],
        max_before_places: item.max_before_places,
        max_order_asking_price_places: item.max_order_asking_price_places,
        max_order_count_places: item.max_order_count_places,
        min_order_count: item.min_order_count,
        min_order_precision: item.min_order_precision,
        show_places : item.show_places,
        spot_commission_rate : spot_commission_rate,
        future_commission_rate : future_commission_rate,
        flags: 0,
        status_spot: item.status_spot ? item.status_spot : null,
        status_future: item.status_future ? item.status_future : null,
        future_leverages: item.future_leverages ? item.future_leverages : [],
        candles: [
        ],
        last24h: [
        ]
      };

      tempProductsMiniTickerData[item.product_id] = {
        price_difference_24h: price_difference,
        percentage_difference_24h: percentage_difference,
        c_24h: item["24h_c"],
        h_24h: item["24h_h"],
        l_24h: item["24h_l"],
        o_24h: item["24h_o"],
        q_24h: item["24h_q"],
        v_24h: item["24h_v"],
        currentPrice: item.price,
        previousCurrentPrice: null,
        currentPriceTimestamp: Date.now(),
        currentPriceLastFirstTradeId: null,
        flags: 0,
      };
    });

    productsCurrencies.forEach((item) => {
      tempCurrenciesData[item.currency] = {
        currency: item.currency,
        currency_name: item.currency_name,
        picture_url: item.picture_url,
        show_places: item.show_places
      };
    });

    state.kprice.productsMiniTicker.data = tempProductsMiniTickerData;
    state.kprice.currencies.data = tempCurrenciesData;
    state.kprice.currencyMaxBeforePlaces = currency_max_before_places;
    
    //console.log(tempProductsData);
    //console.log(tempProductsMiniTickerData);
    //console.log(tempCurrenciesData);
    return tempProductsData;
  },
  miniTicker: (products, state) => {
    //console.log(products)
    const productsMiniTickerData = state.kprice.productsMiniTicker.data;
    const newProductsData = { ...productsMiniTickerData };
    const lastEvents = products.onMiniTickerUpdated.last_events;

    lastEvents.forEach((item) => {
      if(item && item.product_id){
        const item_id = item.product_id;

        // Ürün verisi yoksa yeni bir veri oluştur
        if(!newProductsData[item_id]){
          newProductsData[item_id] = {
            price_difference_24h: null,
            percentage_difference_24h: null,
            c_24h: null,
            h_24h: null,
            l_24h: null,
            o_24h: null,
            q_24h: null,
            v_24h: null,
            currentPrice: null,
            currentPriceTimestamp: Date.now(),
            currentPriceLastFirstTradeId: null,
            previousCurrentPrice: null,
            flags: null,
          };
        }

        if(item.o && item.c){
          const [price_difference, percentage_difference] = calcPriceAndPercentageDifference(item.o, item.c);
          newProductsData[item_id].price_difference_24h = price_difference;
          newProductsData[item_id].percentage_difference_24h = percentage_difference;
        }

        // Güncel değerlerin kaydedilmesi
        newProductsData[item_id].c_24h = item.c ? item.c : null;
        newProductsData[item_id].h_24h = item.h ? item.h : null;
        newProductsData[item_id].l_24h = item.l ? item.l : null;
        newProductsData[item_id].o_24h = item.o ? item.o : null;
        newProductsData[item_id].q_24h = item.q ? item.q : null;
        newProductsData[item_id].v_24h = item.v ? item.v : null;
        
        // CurrentPrice ve currentPriceTimestamp değerlerinin güncellenmesi
        if(item.c && item.last_MT){
          newProductsData[item_id] = setDataWithLastValues(newProductsData[item_id], item.c, item.last_MT);
        }

        if(newProductsData[item_id].currentPrice && newProductsData[item_id].previousCurrentPrice){
          if(newProductsData[item_id].previousCurrentPrice < newProductsData[item_id].currentPrice){
            newProductsData[item_id].flags = 1;
          }
          else if(newProductsData[item_id].previousCurrentPrice > newProductsData[item_id].currentPrice){
            newProductsData[item_id].flags = 2;
          }
          else{
            newProductsData[item_id].flags = 0;
          }
        }
      }
    });
    
    //console.log(newProductsData);
    return newProductsData;
  },
};

const usersUtils = {
  init: (data, state) => {
    //console.log("usersUtils - init");
    //console.log(data);

    let newData = data && data.customers ? data.customers : [];
    newData.forEach(item => {
      if(item.registration_time){
        item.registration_date = moment(item.registration_time).tz(timeZone).format("YYYY-MM-DD HH:mm");
      }
    });

    //console.log(newData);
    return newData;
  }
};

const depositUtils = {
  init: (data, state) => {
    //console.log("depositUtils - init");
    //console.log(data);

    let newData = [];

    if(data && data.type){
      if(data.type == "all" || data.type == "all_assignment"){
        newData = data.deposit_accounts ? data.deposit_accounts : [];
        newData.forEach(item => {
          if(item.adding_timestamp){
            item.adding_date = moment(item.adding_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
          }
          if(item.assignment_time){
            item.assignment_date = moment(item.assignment_time).tz(timeZone).format("YYYY-MM-DD HH:mm");
          }
        });
      }
      else if(data.type == "types_counts"){
        newData = {
          total_full_accounts: data.total_full_accounts ? data.total_full_accounts : 0,
          total_empty_accounts: data.total_empty_accounts ? data.total_empty_accounts : 0
        }
      }
    }
    

    //console.log(newData)
    return newData;
  }
};

const spotUtils = {
  init: (data, state) => {
    //console.log("spotUtils - init");
    //console.log(data);

    const newData = data && data.wallet_items ? data.wallet_items : [];

    const currencyTotals = newData.reduce((acc, item) => {
      const { currency, total_count } = item;
      if (acc[currency]) {
        let currentCount = new Decimal(acc[currency]).plus(new Decimal(total_count));
        acc[currency] = Number(currentCount.toString());
      } else {
        acc[currency] = total_count;
      }
      return acc;
    }, {});

    //console.log(currencyTotals);
    state.kprice.wallet.data = newData;
    return currencyTotals;
  },
  update: (data, state) => {
    //console.log("spotUtils - update");
    //console.log(data);

    const walletStateData = state.kprice.wallet.data;
    
    const newUpdatedData = data && data.onAllCustomerGeneralUpdatedManager ? data.onAllCustomerGeneralUpdatedManager : null;

    if (walletStateData && walletStateData.length > 0 && newUpdatedData && newUpdatedData.message_type === "UpdateWallet") {
      const walletData = [...walletStateData];

      const newWallet = newUpdatedData.extras.reduce((acc, curr) => {
        acc[curr.name] = curr.value;
        return acc;
      }, {});

      const walletIndex = walletData.findIndex(item => item.currency === newWallet.currency && item.customer_id === newWallet.customer_id);

      if (walletIndex !== -1) {
        walletData[walletIndex] = {
          ...walletData[walletIndex],
          ...newWallet
        };
      }

      //console.log(walletData);
      return walletData;
    }
    
    //console.log(walletStateData);
    return walletStateData;
  },
};

const withdrawRequestsUtils = {
  init: (data, state) => {
    //console.log("withdrawRequestsUtils - init");
    //console.log(data);
    const currentCount = data && data.request_items && data.request_items.length ? data.request_items.length : 0;

    const newData = {
      count: currentCount
    };

    //console.log(newData)
    return newData;
  }
};

const futureProfilesUtils = {
  init: (data, state) => {
    //console.log("futureProfilesUtils - init");
    //console.log(data);

    const newData = data;
    if(newData && newData.type && newData.type == "user"){
      const customerIdInfo = newData.customer_id ? newData.customer_id : null;
      if(customerIdInfo && newData.profile_items && newData.profile_items[0]){
        newData.profile_items[0].customer_id = customerIdInfo;
      }
    }

    //console.log(newData);
    return newData;
  },
  update: (data, state) => {
    //console.log("futureProfilesUtils - update");
    //console.log(data);

    const futureProfilesStateData = state.kprice.futureProfiles.data;

    const newUpdatedData = data ? (data.onAllCustomerGeneralUpdatedManager ? data.onAllCustomerGeneralUpdatedManager : (data.onCustomerUpdatedManager ? data.onCustomerUpdatedManager : null)) : null;

    if (futureProfilesStateData && Object.keys(futureProfilesStateData).length > 0 && newUpdatedData && newUpdatedData.message_type === "UpdateFutureProfiles") {
      const futureProfilesData = {...futureProfilesStateData};

      const newProfiles = newUpdatedData.extras.reduce((acc, curr) => {
        acc[curr.name] = curr.value;
        return acc;
      }, {});

      const customerIndex = futureProfilesData.profile_items.findIndex(profile => profile.customer_id === newProfiles.customer_id);

      if (customerIndex !== -1) {
        futureProfilesData.profile_items[customerIndex] = {
          ...futureProfilesData.profile_items[customerIndex],
          ...newProfiles
        };
      } else {
        futureProfilesData.profile_items.push(newProfiles);
      }

      //console.log(futureProfilesData);
      return futureProfilesData;
    }

    //console.log(futureProfilesStateData);
    return futureProfilesStateData;
  },
};

const futureProcessesUtils = {
  init: (data, state) => {
    //console.log("futureProcessesUtils - init");
    //console.log(data);
    
    let returnData = data;
    let newItems = returnData && returnData.process_items ? returnData.process_items : [];
    newItems.forEach(item => {
      if(item.entry_timestamp){
        item.entry_date = moment(item.entry_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
      }
    });
    returnData.process_items = newItems;

    //console.log(returnData);
    return returnData;
  },
  update: (data, state) => {
    //console.log("futureProcessesUtils - update");
    //console.log(data);

    const futureProcessesStateData = state.kprice.futureProcesses.data;

    const newUpdatedData = data ? (data.onAllCustomerGeneralUpdatedManager ? data.onAllCustomerGeneralUpdatedManager : (data.onCustomerUpdatedManager ? data.onCustomerUpdatedManager : null)) : null;

    if (futureProcessesStateData && Object.keys(futureProcessesStateData).length > 0 && newUpdatedData && newUpdatedData.message_type === "UpdateFutureProcesses") {
      const futureProcessesData = {...futureProcessesStateData};

      const newProcess = newUpdatedData.extras.reduce((acc, curr) => {
        acc[curr.name] = curr.value;
        return acc;
      }, {});

      const processIndex = futureProcessesData.process_items.findIndex(process => process.order_id === newProcess.order_id && process.customer_id === newProcess.customer_id);

      if (processIndex !== -1) {
        if (newProcess.state === "active") {
          futureProcessesData.process_items[processIndex] = {
            ...futureProcessesData.process_items[processIndex],
            ...newProcess
          };
        } else if (newProcess.state === "deactive") {
          futureProcessesData.process_items.splice(processIndex, 1);
        }
      } else {
        if (newProcess.state === "active") {
          futureProcessesData.process_items.push(newProcess);
        }
      }

      //console.log(futureProcessesData);
      return futureProcessesData;
    }

    //console.log(futureProcessesStateData);
    return futureProcessesStateData;
  },
};

export {
  timestampToDatetime,
  productsDataUtils,
  usersUtils,
  depositUtils,
  spotUtils,
  withdrawRequestsUtils,
  futureProfilesUtils,
  futureProcessesUtils
};
