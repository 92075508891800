import React, { useEffect, useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from 'react-router-dom';
import Loading from "../components/Global/Loading";
import axios from "axios";
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import moment from "moment-timezone";
import { editNumber_reducing_trailing_zeros, editNumber_reducing_decimal_places, editNumber_increasing_decimal_places, editNumber_separate_with_comma, editNumber_control_decimal_places_length } from '../Lib/calculationFunctions';

const FutureProcessEvents = ({ orderId, customerId }) => {

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    const currenciesData = useSelector((state) => state.kprice.currencies.data);
    const productData = useSelector((state) => state.kprice.products.data);
    const [eventsData, setEventsData] = useState(null);

    const getEventsData = async () => {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        try {
            const response = await axios.get(`https://operationapi.kpriceapp.com/futureprocess/events/?order_id=${orderId}&customer_id=${customerId}`, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });
            
            if(response.status == "200"){
                let details = response.data;

                if(details && details.ProcessEvents && details.ProcessEvents.length > 0){
                    const showPlacesUSDT = currenciesData && currenciesData["USDT"] && currenciesData["USDT"].show_places ? currenciesData["USDT"].show_places : null;

                    for (let i = 0; i < details.ProcessEvents.length; i++) {
                        const productMaxOrderAskingPricePlaces = productData && productData[details.ProcessDetails.product_id] && productData[details.ProcessDetails.product_id].max_order_asking_price_places ? productData[details.ProcessDetails.product_id].max_order_asking_price_places : null;
                        const productShowPlaces = productData && productData[details.ProcessDetails.product_id] && productData[details.ProcessDetails.product_id].show_places ? productData[details.ProcessDetails.product_id].show_places : null;
                        
                        let firstPrice = null;//details.ProcessDetails.entry_price
                        let secondPrice = null;//details.ProcessEvents[i].event_price
    
                        const response_firstPrice = editNumber_control_decimal_places_length(editNumber_reducing_trailing_zeros(details.ProcessDetails.entry_price), productMaxOrderAskingPricePlaces);
                        if(response_firstPrice){
                            firstPrice = editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(details.ProcessDetails.entry_price), productMaxOrderAskingPricePlaces), productMaxOrderAskingPricePlaces));
                        }
                        else{
                            firstPrice = editNumber_separate_with_comma(editNumber_reducing_trailing_zeros(details.ProcessDetails.entry_price));
                        }
    
                        const response_secondPrice = editNumber_control_decimal_places_length(editNumber_reducing_trailing_zeros(details.ProcessEvents[i].event_price), productShowPlaces);
                        if(response_secondPrice){
                            secondPrice = editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(details.ProcessEvents[i].event_price), productShowPlaces), productShowPlaces));
                        }
                        else{
                            secondPrice = editNumber_separate_with_comma(editNumber_reducing_trailing_zeros(details.ProcessEvents[i].event_price));
                        }
    
                        let eventDate = moment(Number(details.ProcessEvents[i].event_timestamp)).tz(timeZone).format("YYYY-MM-DD HH:mm");
                        details.ProcessEvents[i].eventDate = eventDate;
                        details.ProcessEvents[i].eventCount = editNumber_separate_with_comma(editNumber_reducing_trailing_zeros(details.ProcessEvents[i].close_count));
                        details.ProcessEvents[i].firstPrice = firstPrice;
                        details.ProcessEvents[i].secondPrice = secondPrice;
                        details.ProcessEvents[i].eventProfitClass = details.ProcessEvents[i].profit > 0 ? "success" : (details.ProcessEvents[i].profit < 0 ? "danger" : "");
                        details.ProcessEvents[i].eventProfit = editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(details.ProcessEvents[i].profit), showPlacesUSDT), showPlacesUSDT));
    
                        if(details.ProcessEvents[i].query_type){
                            const submenuLeft = document.getElementsByClassName("submenu-process-values-left");
                            for(let x = 0; x < submenuLeft.length; x++){
                                if(submenuLeft[x].dataset.orderid == details.ProcessDetails.order_id){
                                    if(details.ProcessEvents[i].query_type == "take_profit"){
                                        submenuLeft[x].getElementsByClassName("take_profit")[0].classList.add("danger");
                                    }
                                    else if(details.ProcessEvents[i].query_type == "stop_loss"){
                                        submenuLeft[x].getElementsByClassName("stop_loss")[0].classList.add("danger");
                                    }
                                }
                            }
                        }
                    }

                    setEventsData(details.ProcessEvents);
                }
                else{
                    setEventsData([]);
                }
            }
            else{
                toast.error("There was a problem loading position values!");
            }
        }
        catch (error) {
            toast.error("There was a problem loading position values!");
        }
    }

    useEffect(() => {
        if(orderId && customerId){
            getEventsData();
        }
    }, [orderId, customerId]);
    
    return (
        <>
            {eventsData ? <>
                {eventsData.length > 0 ? 
                    <div className="details">
                        {eventsData.map((item, index) => (
                            <div className="details-item" key={index}>
                                <div><span>Date</span><label>{item.eventDate}</label></div>
                                <div><span>Count</span><label>{item.eventCount}</label></div>
                                <div><span>Price</span><label>{item.firstPrice} <i className="fa-solid fa-arrow-right"></i> {item.secondPrice}</label></div>
                                <div><span>Profit</span><label className={item.eventProfitClass}>{item.eventProfit}</label></div>
                            </div>
                        ))}
                    </div>
                : <div className="empty-details"><i className="fa-regular fa-folder-open"></i><span>No details</span></div>}
            </> : <div className="empty-details"><div className="loading"></div><Loading /></div>}
        </>
    );
    
}

export default FutureProcessEvents;