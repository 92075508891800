import React, { useEffect, useState, useMemo, useRef } from "react";
import { MaterialReactTable } from 'material-react-table';
import { useDispatch, useSelector } from "react-redux";
import { getPriceForCurrency, editNumber_reducing_trailing_zeros_special, editNumber_reducing_decimal_places, editNumber_increasing_decimal_places, editNumber_separate_with_comma } from '../Lib/calculationFunctions';
import Loading from "../components/Global/Loading";
import axios from "axios";
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import Decimal from 'decimal.js';
import Tooltip from '@mui/material/Tooltip';
import moment from "moment-timezone";
import { onSpotOpen, onWithdrawRequestsOpen } from "../Reducer/kpriceReducer";

const WithdrawRequests = () => {

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const dispatch = useDispatch();

    const productsMiniTickerData = useSelector((state) => state.kprice.productsMiniTicker.data);
    const currenciesData = useSelector((state) => state.kprice.currencies.data);
    const walletData = useSelector((state) => state.kprice.wallet.data);

    const [requestData, setRequestData] = useState(null);
    const tableArea = useRef(null);//tablo alanı

    const [input1Values, setInput1Values] = useState(requestData ? Array(requestData.length).fill('') : []);
    const [input2Values, setInput2Values] = useState(requestData ? Array(requestData.length).fill('') : []);
    const input1Refs = useRef([]);
    const input2Refs = useRef([]);
    const [button1Loading, setButton1Loading] = useState(requestData ? Array(requestData.length).fill('') : []);
    const [button2Loading, setButton2Loading] = useState(requestData ? Array(requestData.length).fill('') : []);

    const getWithdrawRequests = async () => {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        try {
            const response = await axios.get(`https://operationapi.kpriceapp.com/withdraw/request?type=all`, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });
            if(response.status == "200"){
                let currentData = response.data.request_items;
                currentData.forEach(item => {
                    if(item.event_timestamp){
                        item.event_timestamp = Number(item.event_timestamp);
                        item.event_date = moment(item.event_timestamp).tz(timeZone).format("YYYY-MM-DD HH:mm");
                    }
                    if(item.count){
                        item.count = Number(item.count);
                    }
                    if(item.fee_count){
                        item.fee_count = Number(item.fee_count);
                    }
                    if(item.fee_usd){
                        item.fee_usd = Number(item.fee_usd);
                    }
                    if(item.min_amount_usd){
                        item.min_amount_usd = Number(item.min_amount_usd);
                    }
                    if(item.amount_usd){
                        item.amount_usd = Number(item.amount_usd);
                    }
                    if(item.net_count){
                        item.net_count = Number(item.net_count);
                    }
                });
                setRequestData(currentData);
            }
            else{
                toast.error("There was a problem loading withdraw requests!");
            }
        }
        catch (error) {
            toast.error("There was a problem loading withdraw requests!");
        }
    }

    useEffect(() => {
        if(requestData == null){
            getWithdrawRequests();
        }
        setInput1Values(requestData ? Array(requestData.length).fill('') : []);
        setInput2Values(requestData ? Array(requestData.length).fill('') : []);
    }, [requestData]);

    useEffect(() => {
        dispatch(onWithdrawRequestsOpen());
    }, []);

    const ProfileButton = ({ customer_id }) => {
        return (
            <>
                <div className="td-text-with-button customer-id">{customer_id}</div>
                <Tooltip title="Profile" className="detail-button" placement="right">
                    <a href={`/profile?customer_id=${customer_id}`} target="_blank">
                        <i className="fa-solid fa-user"></i>
                    </a>
                </Tooltip>
            </>
        );
    }

    const TableInfo = ({ item, columnNumber }) => {
        if(columnNumber == "1"){
            return <>
                <div className="element-area">
                    {item.customer_id && 
                        <div className="item">
                            <label>Customer ID:</label>
                            <span><ProfileButton customer_id={item.customer_id} /></span>
                        </div>
                    }
                    {item.event_user_id && 
                        <div className="item">
                            <label>Event User:</label>
                            <span>{item.event_user_id}</span>
                        </div>}
                    {item.event_date && 
                        <div className="item">
                            <label>Event Date:</label>
                            <span>{item.event_date}</span>
                        </div>}
                    {item.withdraw_type && 
                        <div className="item">
                            <label>Withdraw Type:</label>
                            <span>{item.withdraw_type == "crypto_address" ? "Crypto Address" : item.withdraw_type}</span>
                        </div>}
                </div>
            </>;
        }
        else if(columnNumber == "2"){
            return <>
                <div className="element-area">
                    {item.count && 
                        <div className="item">
                            <label>Count:</label>
                            <span>{item.count}</span>
                        </div>}
                    {item.currency && 
                        <div className="item">
                            <label>Currency:</label>
                            <span>{item.currency}</span>
                        </div>}
                    {item.network && 
                        <div className="item">
                            <label>Network:</label>
                            <span>{item.network}</span>
                        </div>}
                    {item.address && 
                        <div className="item">
                            <label>Address:</label>
                            <span>{item.address}</span>
                        </div>}
                </div>
            </>;
        }
        else if(columnNumber == "3"){
            const value = walletData && walletData.length ? walletData.find(value => value.customer_id == item.customer_id && value.currency == item.currency) : null;
            const currentTotalCount = value ? value.total_count : <div style={{color: "transparent"}}>-</div>;

            let minAmountCurrency = "";
            if(item.fee_currency != "USDT"){
                const selectedCoinCurrentPrice = getPriceForCurrency(item.fee_currency, productsMiniTickerData);
                let selectedCoinShowPlaces = null;
                if(currenciesData[item.fee_currency] && currenciesData[item.fee_currency].show_places){
                    selectedCoinShowPlaces = currenciesData[item.fee_currency].show_places;
                }
                minAmountCurrency = editNumber_separate_with_comma(editNumber_reducing_trailing_zeros_special(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places((new Decimal(item.min_amount_usd).div(new Decimal(selectedCoinCurrentPrice ? selectedCoinCurrentPrice : 1)).toString()), selectedCoinShowPlaces), selectedCoinShowPlaces)));
            }
            return <>
                <div className="element-area element-area2">
                    <div className="left">
                        <div className="item">
                            <label>{item.currency} balance</label>
                            <span>{currentTotalCount}</span>
                        </div>
                        <div className="item">
                            <label>Network fee</label>
                            <span>$ {item.fee_usd}{item.fee_currency != "USDT" && ` ≈ ${item.fee_count} ${item.fee_currency}`}</span>
                        </div>
                    </div>
                    <div className="right">
                        <div className="item">
                            <label>Minimum withdrawal</label>
                            <span>$ {item.min_amount_usd}{item.fee_currency != "USDT" && ` ≈ ${minAmountCurrency} ${item.fee_currency}`}</span>
                        </div>
                        <div className="item">
                            <label>Net amount</label>
                            <span>{item.net_count}</span>
                        </div>
                    </div>
                </div>
            </>;
        }
        else{
            return <></>;
        }
    }

    const input1Validation = (value) => {
        const regex = /^[a-zA-Z0-9_.-]{1,512}$/;
        if(value){
            return regex.test(value);
        }
        else{
            return false;
        }
    }

    const input2Validation = (value) => {
        if(value){
            const generalPattern = /^[^'`\"“”‘’«»‹›<>;\{\}\$\&\\\%\*\@\!\?\#\^\|\[\]\+\=\~\/\(\)]*$/;
            const noDoubleSpaceOrTrimPattern = /^(?! )(?!.*  )(?!.* $).+$/;
            const noOtherSpacesPattern = /^[^\t\n\r]*$/;
            const charLimitPattern512 = /^.{1,512}$/;
    
            if (
                !generalPattern.test(value) ||
                !noDoubleSpaceOrTrimPattern.test(value) ||
                !noOtherSpacesPattern.test(value) ||
                !charLimitPattern512.test(value)
            ) {
                return false;
            }
            return true;
        }
        else{
            return false;
        }
    }

    const handleInputChange = (index, event, inputType) => {
        const { value } = event.target;
        clearDangerClass(index);
        if (inputType === 'input1') {
            const updatedValues = [...input1Values];
            updatedValues[index] = value;
            setInput1Values(updatedValues);
            
            if(input1Validation(value)){
                input1Refs.current[index].classList.remove('danger');
            }
            else{
                input1Refs.current[index].classList.add('danger');
            }
        }
        else if (inputType === 'input2') {
            const updatedValues = [...input2Values];
            updatedValues[index] = value;
            setInput2Values(updatedValues);

            if(input2Validation(value)){
                input2Refs.current[index].classList.remove('danger');
            }
            else{
                input2Refs.current[index].classList.add('danger');
            }
        }
    };

    const resultWithdraw = async (item, index, resultType) => {
        const currentButtons = resultType == "approved" ? [...button1Loading] : [...button2Loading];
        currentButtons[index] = true;
        if(resultType == "approved"){
            setButton1Loading(currentButtons);
        }
        else{
            setButton2Loading(currentButtons);
        }

        const input1Value = input1Values[index];
        const input2Value = input2Values[index];
        let input1IsValid = null;
        let input2IsValid = null;

        if(resultType == "approved"){
            clearDangerClass(index);
            input1IsValid = input1Validation(input1Value);
            input2IsValid = input2Validation(input2Value);

            if(input1IsValid){
                input1Refs.current[index].classList.remove('danger');
            }
            else{
                input1Refs.current[index].classList.add('danger');
                if(input1Value){
                    toast.error("Transaction ID information is incorrect!");
                }
                else{
                    toast.error("Transaction ID information cannot be empty!");
                }
            }

            if(input2IsValid){
                input2Refs.current[index].classList.remove('danger');
            }
            else{
                input2Refs.current[index].classList.add('danger');
                if(input2Value){
                    toast.error("Extra Info information is incorrect!");
                }
                else{
                    toast.error("Extra Info information cannot be empty!");
                }
            }
        }
        else{
            clearDangerClass(null);
        }
        
        if((resultType == "approved" && input1IsValid && input2IsValid) || resultType == "denied"){
            let updateData = {};

            if(resultType == "approved"){
                updateData = {
                    log_id: item.log_id,
                    customer_id: item.customer_id,
                    request_result: "approved",
                    transaction_id: input1Value,
                    extra_info: input2Value
                }
            }
            else{
                updateData = {
                    log_id: item.log_id,
                    customer_id: item.customer_id,
                    request_result: "denied"
                }
            }
    
            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();
            
            axios.post('https://operationapi.kpriceapp.com/withdraw/result', 
                JSON.stringify(updateData),
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
            )
            .then(function (response) {
                if(response.status == "200"){
                    dispatch(onSpotOpen());
                    dispatch(onWithdrawRequestsOpen());
                    setRequestData(null);
                    toast.success("The withdraw operation was successfully!");
                }
                else{
                    toast.error(`The withdraw operation was failed!${response.data.message ? ` (${response.data.message})` : ""}`);
                }
            })
            .catch(function (error) {
                toast.error(`The withdraw operation was failed!${error.response.data.error ? ` (${error.response.data.error})` : ""}`);
            })
            .finally(function () {
                const currentButtons = resultType == "approved" ? [...button1Loading] : [...button2Loading];
                currentButtons[index] = false;
                if(resultType == "approved"){
                    setButton1Loading(currentButtons);
                }
                else{
                    setButton2Loading(currentButtons);
                }
            });
        }
        else{
            const currentButtons = resultType == "approved" ? [...button1Loading] : [...button2Loading];
            currentButtons[index] = false;
            if(resultType == "approved"){
                setButton1Loading(currentButtons);
            }
            else{
                setButton2Loading(currentButtons);
            }
        }
    };

    const clearDangerClass = (index) => {
        input1Refs.current.forEach((ref, idx) => {
            if (index === null || idx !== index) {
                if (ref) {
                    ref.classList.remove('danger');
                }
            }
        });
        input2Refs.current.forEach((ref, idx) => {
            if (index === null || idx !== index) {
                if (ref) {
                    ref.classList.remove('danger');
                }
            }
        });
    };

    const tableColumns = useMemo(() => [//tablo başlıkları
        { accessorKey: 'event_timestamp', header: 'Customer', size: 200, Cell: ({ row }) => row.original.customerForTable },
        { accessorKey: 'count', header: 'Count', size: 200, Cell: ({ row }) => row.original.countForTable },
        { accessorKey: 'net_count', header: 'Details', size: 450, Cell: ({ row }) => row.original.detailsForTable },
        { accessorKey: 'informations', header: 'Informations', size: 10 },
        { accessorKey: 'result_buttons', header: 'Result Buttons', size: 10 },
    ], []);

    const tableData = useMemo(() => {//users tablo bilgileri
        const newData = [];

        if(requestData && requestData.length > 0){
            requestData.map((item, index) => {
                newData.push({
                    event_timestamp: item.event_timestamp,
                    customerForTable: <TableInfo item={item} columnNumber="1" />,
                    count: item.count,
                    countForTable: <TableInfo item={item} columnNumber="2" />,
                    net_count: item.net_count,
                    detailsForTable: <TableInfo item={item} columnNumber="3" />,
                    informations: <div className="table-inputs"><div className="item"><label>Transaction ID</label><input type="text" className="table-input" value={input1Values && input1Values[index] ? input1Values[index] : ""} placeholder="Transaction ID" onChange={(e) => handleInputChange(index, e, 'input1')} ref={(el) => (input1Refs.current[index] = el)} /></div><div className="item"><label>Extra Info</label><input type="text" className="table-input" value={input2Values && input2Values[index] ? input2Values[index] : ""} placeholder="Extra Info" onChange={(e) => handleInputChange(index, e, 'input2')} ref={(el) => (input2Refs.current[index] = el)} /></div></div>,
                    result_buttons: <div className="result-buttons"><button type="button" className="result-button success" onClick={() => resultWithdraw(item, index, "approved")} disabled={(button1Loading && button1Loading.length > 0 && button1Loading.some(value => value === true)) || (button2Loading && button2Loading.length > 0 && button2Loading.some(value => value === true))}>{button1Loading && button1Loading.length > 0 && button1Loading[index] && button1Loading[index] == true ? <Loading /> : "Accept"}</button><button type="button" className="result-button danger" onClick={() => resultWithdraw(item, index, "denied")} disabled={(button1Loading && button1Loading.length > 0 && button1Loading.some(value => value === true)) || (button2Loading && button2Loading.length > 0 && button2Loading.some(value => value === true))}>{button2Loading && button2Loading.length > 0 && button2Loading[index] && button2Loading[index] == true ? <Loading /> : "Reject"}</button></div>
                })
            })
        }

        return newData;
    }, [requestData, productsMiniTickerData, currenciesData, walletData, input1Values, input2Values, input1Refs, input2Refs, button1Loading, button2Loading]);

    return (
        <div className="main">
            <div className="allsections allsections-border table-area">
                <div ref={tableArea} className={requestData ? "list" : "list loading"}>
                    {requestData ? 
                        <div className="columnWithdrawRequestsSpecial">
                            <MaterialReactTable columns={tableColumns} data={tableData} initialState={{sorting: [{id: 'event_timestamp', desc: true}]}} />
                        </div>
                    : <Loading />}
                </div>
            </div>
        </div>
    );
};

export default WithdrawRequests;