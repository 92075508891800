import React, { useEffect, useState, useMemo, useContext } from "react";
import { useSelector } from "react-redux";
import { MaterialReactTable } from 'material-react-table';
import { editNumber_reducing_trailing_zeros, editNumber_reducing_decimal_places, editNumber_increasing_decimal_places, editNumber_separate_with_comma, editNumber_control_decimal_places_length } from '../Lib/calculationFunctions';
import withLoadingData from "../Container/withLoadingData";
import isEqual from "react-fast-compare";
import Loading from "../components/Global/Loading";

const DashboardCurrencyStatus = ({ type, isProductsLoading }) => {

    const [tableProducts, setTableProducts] = useState(null);//tablo verileri
    const columnsProducts = useMemo(() => [//tablo bilgileri
        { accessorKey: 'name', header: 'Name', size: 50, Cell: ({ row }) => row.original.nameForTable },//row.original.nameForTable geçen yer newData içindeki changeForSorting değeri
        { accessorKey: 'price', header: 'Price', size: 30, Cell: ({ row }) => row.original.priceForTable },
        { accessorKey: 'change', header: 'Change', size: 30, Cell: ({ row }) => row.original.changeForTable },//row.original.changeForTable geçen yer newData içindeki changeForSorting değeri
        { accessorKey: 'quantity', header: 'Quantity', show: false },
    ], []);

    const productsData = useSelector((state) => state.kprice.products.data);
    const productsMiniTickerData = useSelector((state) => state.kprice.productsMiniTicker.data);
    const currenciesData = useSelector((state) => state.kprice.currencies.data);

    const calcPrice = (price, show_places) => {
        const response = editNumber_control_decimal_places_length(editNumber_reducing_trailing_zeros(price), show_places);
        if(response){
            return editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(price), show_places), show_places));
        }
        else{
            return editNumber_separate_with_comma(editNumber_reducing_trailing_zeros(price));
        }
    }

    const tableProducts_name = (key) => {
        let currenciesName = key.split("-")[0];
        return <div className="section-currencystatus-element-link chart">
            <div className="section-currencystatus-element-name">
                <div className="innerlink">
                    <img src={currenciesData && currenciesData[currenciesName] ? currenciesData[currenciesName].picture_url : undefined} />
                </div>
                <div className="innerlink">
                    <div className="nametext">
                        <label>{currenciesData && currenciesData[currenciesName] ? currenciesData[currenciesName].currency_name : ""}</label>
                        <span><p>{key.split("-")[0]}</p>/{key.split("-")[1]}</span>
                    </div>
                </div>
            </div>
        </div>;
    }

    const tableProducts_price = (key) => {
        let show_places = productsData && productsData[key] && productsData[key].show_places ? productsData[key].show_places : "NaN";
        let currentPrice = productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].currentPrice ? productsMiniTickerData[key].currentPrice : null;
        let previousCurrentPrice = productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].previousCurrentPrice ? productsMiniTickerData[key].previousCurrentPrice : null;
        
        if(currentPrice && previousCurrentPrice && document.getElementById(`status-element-${key}`)){
            let currentCP = document.getElementById(`status-element-${key}`).dataset.current_price;
            let currentPCP = document.getElementById(`status-element-${key}`).dataset.previous_current_price;
            if(currentCP != currentPrice || currentPCP != previousCurrentPrice){
                if(currentPrice > previousCurrentPrice){
                    document.getElementById(`status-element-${key}`).classList.remove("success", "danger");
                    setTimeout(() => {
                        if(document.getElementById(`status-element-${key}`)){
                            document.getElementById(`status-element-${key}`).classList.add("success");
                        }
                    }, 10);
                }
                else if(currentPrice < previousCurrentPrice){
                    document.getElementById(`status-element-${key}`).classList.remove("success", "danger");
                    setTimeout(() => {
                        if(document.getElementById(`status-element-${key}`)){
                            document.getElementById(`status-element-${key}`).classList.add("danger");
                        }
                    }, 10);
                }
                document.getElementById(`status-element-${key}`).dataset.current_price = currentPrice;
                document.getElementById(`status-element-${key}`).dataset.previous_current_price = previousCurrentPrice;
            }
        }
        return <div className="section-currencystatus-element-link">
            <div id={`status-element-${key}`} className="section-currencystatus-element-price riseAndFallValueAnimation" data-current_price="" data-previous_current_price="">
                {currentPrice ? calcPrice(currentPrice, show_places) : "NaN"}
            </div>
        </div>;
    }

    const tableProducts_change = (key) => {
        return <div className="section-currencystatus-element-link">
            <div className="section-currencystatus-element-change">
                <div className={productsMiniTickerData && productsMiniTickerData[key] ? (productsMiniTickerData[key].percentage_difference_24h != null ? (productsMiniTickerData[key].percentage_difference_24h >= 0 ? "success" : "danger") : "danger") : "danger"}>{productsMiniTickerData && productsMiniTickerData[key] ? (productsMiniTickerData[key].percentage_difference_24h != null ? editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(productsMiniTickerData[key].percentage_difference_24h), 2), 2)) : "NaN") : "NaN"}%</div>
            </div>
        </div>;
    }

    useEffect(() => {
        const productKeys = productsMiniTickerData ? Object.keys(productsMiniTickerData) : [];

        const processData = () => {
            const newData = [];
            
            productKeys.forEach((key) => {
                let productStatusSpotActive = type != "spot_deactive" && type != "future_deactive" && (productsData && productsData[key] && productsData[key].status_spot ? (productsData[key].status_spot == "active" ? true : false) : true);
                let productStatusSpotDeactive = type == "spot_deactive" && (productsData && productsData[key] && productsData[key].status_spot ? (productsData[key].status_spot != "active" ? true : false) : false);
                let productStatusFutureDeactive = type == "future_deactive" && (productsData && productsData[key] && productsData[key].status_future ? (productsData[key].status_future != "active" ? true : false) : false);
                if(productStatusSpotActive || productStatusSpotDeactive || productStatusFutureDeactive){
                    newData.push({
                        name: currenciesData && currenciesData[key.split("-")[0]] ? currenciesData[key.split("-")[0]].currency_name : "",
                        nameForTable: tableProducts_name(key),
                        price: productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].currentPrice ? productsMiniTickerData[key].currentPrice : 0,
                        priceForTable: tableProducts_price(key),
                        change: productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].percentage_difference_24h != null ? productsMiniTickerData[key].percentage_difference_24h : 0,
                        changeForTable: tableProducts_change(key),
                        quantity: productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].q_24h != null ? productsMiniTickerData[key].q_24h : 0,
                    });
                }
            });
            
            return newData;
        };

        setTableProducts(processData());
    }, [productsMiniTickerData, currenciesData, type]);
    
    return(
        <div id="section-currencystatus" className="section-currencystatus">
            {isProductsLoading != false || tableProducts == null ? (//undefined ya da true geldiyse loading gösteriliyor, false ise grafik
                <div className="currencyStatusLoading"><Loading /></div>
            ) : (
                <MaterialReactTable 
                    columns={columnsProducts} 
                    data={tableProducts} 
                    enablePagination={false}
                    enableRowVirtualization={true}
                    initialState={{
                        columnVisibility: { quantity: false },
                        sorting: type == "hot" ? [{id: 'quantity', desc: true}] : (type == "positive" ? [{id: 'change', desc: true}] : [{id: 'change', desc: false}])
                    }} 
                    className="section-currencystatus-table" 
                />
            )}
        </div>
    )
    
}

export default withLoadingData()(React.memo(DashboardCurrencyStatus, isEqual));