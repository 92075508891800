import {
  requestActions,
  createRequestSaga,
  createAppSyncSubscriptionThunk,
  createAppSyncSubscriptionThunkIAM,
  createChangeOptionSaga,
  changeOptionActions,
  unsubscribeFunctions
} from "../Lib/asyncUtil";
import { productsDataUtils, usersUtils, depositUtils, spotUtils, withdrawRequestsUtils, futureProfilesUtils, futureProcessesUtils } from "../Lib/utils";
import { kpriceApi } from "../Api/api";
import { takeEvery, put, take, fork, call, select } from "redux-saga/effects";

const START_INIT = "coin/START_INIT";
const ON_USER_LOADED_INIT = "coin/ON_USER_LOADED_INIT";
const ON_USER_LOGGED_OUT = "coin/ON_USER_LOGGED_OUT";

const GET_PRODUCT_IDS = "coin/GET_PRODUCT_IDS";
const GET_PRODUCT_IDS_SUCCESS = "coin/GET_PRODUCT_IDS_SUCCESS";
const GET_PRODUCT_IDS_ERROR = "coin/GET_PRODUCT_IDS_ERROR";

const GET_USERS = "coin/GET_USERS";
const GET_USERS_SUCCESS = "coin/GET_USERS_SUCCESS";
const GET_USERS_ERROR = "coin/GET_USERS_ERROR";
const ON_USERS_OPEN = "coin/ON_USERS_OPEN";
const ON_CHANGE_USERS_TYPE = "coin/ON_CHANGE_USERS_TYPE";

const GET_DEPOSIT = "coin/GET_DEPOSIT";
const GET_DEPOSIT_SUCCESS = "coin/GET_DEPOSIT_SUCCESS";
const GET_DEPOSIT_ERROR = "coin/GET_DEPOSIT_ERROR";
const ON_CHANGE_DEPOSIT_TYPE = "coin/ON_CHANGE_DEPOSIT_TYPE";

const GET_SPOT = "coin/GET_SPOT";
const GET_SPOT_SUCCESS = "coin/GET_SPOT_SUCCESS";
const GET_SPOT_ERROR = "coin/GET_SPOT_ERROR";
const ON_SPOT_OPEN = "coin/ON_SPOT_OPEN";

const GET_WITHDRAW_REQUESTS = "coin/GET_WITHDRAW_REQUESTS";
const GET_WITHDRAW_REQUESTS_SUCCESS = "coin/GET_WITHDRAW_REQUESTS_SUCCESS";
const GET_WITHDRAW_REQUESTS_ERROR = "coin/GET_WITHDRAW_REQUESTS_ERROR";
const ON_WITHDRAW_REQUESTS_OPEN = "coin/ON_WITHDRAW_REQUESTS_OPEN";

const GET_FUTURE_PROFILES = "coin/GET_FUTURE_PROFILES";
const GET_FUTURE_PROFILES_SUCCESS = "coin/GET_FUTURE_PROFILES_SUCCESS";
const GET_FUTURE_PROFILES_ERROR = "coin/GET_FUTURE_PROFILES_ERROR";

const GET_FUTURE_PROCESSES = "coin/GET_FUTURE_PROCESSES";
const GET_FUTURE_PROCESSES_SUCCESS = "coin/GET_FUTURE_PROCESSES_SUCCESS";
const GET_FUTURE_PROCESSES_ERROR = "coin/GET_FUTURE_PROCESSES_ERROR";

const ON_FUTURE_PROFILE_OPEN = "coin/ON_FUTURE_PROFILE_OPEN";
const ON_FUTURE_PROFILE_CLOSE = "coin/ON_FUTURE_PROFILE_CLOSE";
const ON_GENERAL_LIST_OPEN = "coin/ON_GENERAL_LIST_OPEN";
const ON_GENERAL_LIST_CLOSE = "coin/ON_GENERAL_LIST_CLOSE";
const ON_ACTIVE_PROCESSES_OPEN = "coin/ON_ACTIVE_PROCESSES_OPEN";
const ON_ACTIVE_PROCESSES_CLOSE = "coin/ON_ACTIVE_PROCESSES_CLOSE";

const CONNECT_MINI_TICKER_UPDATED = "CONNECT_MINI_TICKER_UPDATED";
const CONNECT_MINI_TICKER_UPDATED_SUCCESS = "CONNECT_MINI_TICKER_UPDATED_SUCCESS";
const CONNECT_MINI_TICKER_UPDATED_ERROR = "CONNECT_MINI_TICKER_UPDATED_ERROR";

const CONNECT_CUSTOMER_UPDATED_MANAGER = "CONNECT_CUSTOMER_UPDATED_MANAGER";
const CONNECT_CUSTOMER_UPDATED_MANAGER_SUCCESS = "CONNECT_CUSTOMER_UPDATED_MANAGER_SUCCESS";
const CONNECT_CUSTOMER_UPDATED_MANAGER_ERROR = "CONNECT_CUSTOMER_UPDATED_MANAGER_ERROR";

const CONNECT_CUSTOMER_GENERAL_UPDATED_MANAGER = "CONNECT_CUSTOMER_GENERAL_UPDATED_MANAGER";
const CONNECT_CUSTOMER_GENERAL_UPDATED_MANAGER_SUCCESS = "CONNECT_CUSTOMER_GENERAL_UPDATED_MANAGER_SUCCESS";
const CONNECT_CUSTOMER_GENERAL_UPDATED_MANAGER_ERROR = "CONNECT_CUSTOMER_GENERAL_UPDATED_MANAGER_ERROR";

const getProductIdsSaga = createRequestSaga(
  GET_PRODUCT_IDS,
  kpriceApi.getProductIds,
  productsDataUtils.init
);

const getUsersSaga = createRequestSaga(
  GET_USERS,
  kpriceApi.getUsers,
  usersUtils.init
);

const getDepositSaga = createRequestSaga(
  GET_DEPOSIT,
  kpriceApi.getDeposit,
  depositUtils.init
);

const getSpotSaga = createRequestSaga(
  GET_SPOT,
  kpriceApi.getSpot,
  spotUtils.init
);

const getWithdrawRequestsSaga = createRequestSaga(
  GET_WITHDRAW_REQUESTS,
  kpriceApi.getWithdrawRequests,
  withdrawRequestsUtils.init
);

const getFutureProfiles = createRequestSaga(
  GET_FUTURE_PROFILES,
  kpriceApi.getFutureProfiles,
  futureProfilesUtils.init
);

const getFutureProcesses = createRequestSaga(
  GET_FUTURE_PROCESSES,
  kpriceApi.getFutureProcesses,
  futureProcessesUtils.init
);

const connectMiniTickerAppSyncSubscription = createAppSyncSubscriptionThunkIAM(
  CONNECT_MINI_TICKER_UPDATED,
  productsDataUtils.miniTicker
);

const connectCustomerUpdatedManagerAppSyncSubscription = createAppSyncSubscriptionThunkIAM(
  CONNECT_CUSTOMER_UPDATED_MANAGER,
  [
    { matchingType: 'UpdateFutureProfiles', dataMaker: (data, currentState) => ({ key: 'futureProfiles', payload: futureProfilesUtils.update(data, currentState) }) },
    { matchingType: 'UpdateFutureProcesses', dataMaker: (data, currentState) => ({ key: 'futureProcesses', payload: futureProcessesUtils.update(data, currentState) }) },
  ]
);

const connectCustomerGeneralUpdatedManagerAppSyncSubscription = createAppSyncSubscriptionThunkIAM(
  CONNECT_CUSTOMER_GENERAL_UPDATED_MANAGER,
  [
    { matchingType: 'UpdateWallet', dataMaker: (data, currentState) => ({ key: 'wallet', payload: spotUtils.update(data, currentState) }) },
    { matchingType: 'UpdateFutureProfiles', dataMaker: (data, currentState) => ({ key: 'futureProfiles', payload: futureProfilesUtils.update(data, currentState) }) },
    { matchingType: 'UpdateFutureProcesses', dataMaker: (data, currentState) => ({ key: 'futureProcesses', payload: futureProcessesUtils.update(data, currentState) }) },
  ]
);

function* kpriceSaga() {
  yield takeEvery(START_INIT, startInittSaga);
  yield takeEvery(ON_USER_LOADED_INIT, onUserLoadedSaga);
  yield takeEvery(ON_USER_LOGGED_OUT, onUserLoggedOutSaga);

  yield takeEvery(GET_PRODUCT_IDS, getProductIdsSaga);

  yield takeEvery(GET_USERS, getUsersSaga);
  yield takeEvery(ON_USERS_OPEN, onUsersOpenSaga);
  yield takeEvery(ON_CHANGE_USERS_TYPE, onChangeUsersTypeSaga);
  
  yield takeEvery(GET_DEPOSIT, getDepositSaga);
  yield takeEvery(ON_CHANGE_DEPOSIT_TYPE, onChangeDepositTypeSaga);

  yield takeEvery(GET_SPOT, getSpotSaga);
  yield takeEvery(ON_SPOT_OPEN, onSpotOpenSaga);

  yield takeEvery(GET_WITHDRAW_REQUESTS, getWithdrawRequestsSaga);
  yield takeEvery(ON_WITHDRAW_REQUESTS_OPEN, onWithdrawRequestsOpenSaga);
  
  yield takeEvery(ON_FUTURE_PROFILE_OPEN, onFutureProfileOpenSaga);
  yield takeEvery(ON_FUTURE_PROFILE_CLOSE, onFutureProfileCloseSaga);
  yield takeEvery(ON_GENERAL_LIST_OPEN, onGeneralListOpenSaga);
  yield takeEvery(ON_GENERAL_LIST_CLOSE, onGeneralListCloseSaga);
  yield takeEvery(ON_ACTIVE_PROCESSES_OPEN, onActiveProcessesOpenSaga);
  yield takeEvery(ON_ACTIVE_PROCESSES_CLOSE, onActiveProcessesCloseSaga);
}

const initialState = {
  usersType: "all",
  depositType: "all",
  currencyMaxBeforePlaces: null,
  users: {
    error: false,
    data: {
    },
  },
  deposit: {
    error: false,
    data: {
    },
  },
  spot: {
    error: false,
    data: {
    },
  },
  wallet: {
    error: false,
    data: {
    },
  },
  products: {
    error: false,
    data: {
    },
  },
  productsMiniTicker: {
    error: false,
    data: {
    },
  },
  currencies: {
    error: false,
    data: {
    },
  },
  withdrawRequests: {
    error: false,
    data: {
    },
  },
  futureProfiles: {
    error: false,
    data: {
    },
  },
  futureProcesses: {
    error: false,
    data: {
    },
  },
};

function endSubscription(key) {
  if (unsubscribeFunctions[key]) {
    //console.log(`${key} aboneliği sonlandırılıyor...`);
    unsubscribeFunctions[key].unsubscribe();
    unsubscribeFunctions[key] = null;
    //console.log(`${key} aboneliği sonlandırıldı.`);
  }
}

const startInit = () => ({ 
  type: START_INIT
});
function* startInittSaga() {//uygulama ilk yüklendiği anda çalışan fonksiyon
  //console.log("startInittSaga - uygulama çalıştığında çalışıyor, şimdilik içinde hiçbir işlem yapılmıyor");
  const state = yield select();
  
}

const onUserLoadedInit = (username) => ({
  type: ON_USER_LOADED_INIT,
  payload: username,
});
function* onUserLoadedSaga(action) {//kullanıcı giriş yaptığında - ilk bilgiler
  //console.log("onUserLoadedSaga - ilk bilgiler yüklendi");
  const state = yield select();

  try {
    yield call(getProductIdsSaga);
  } catch (error) {}

  yield put(connectMiniTickerAppSyncSubscription());

  try {
    yield call(getDepositSaga, {
      payload: {
        type: state.kprice.depositType,
      },
    });
  } catch (error) {}

  try {
    yield call(getSpotSaga);
  } catch (error) {}

  try {
    yield call(getWithdrawRequestsSaga);
  } catch (error) {}
}

const onUserLoggedOut = () => ({
  type: ON_USER_LOGGED_OUT,
});
function* onUserLoggedOutSaga() {//kullanıcı çıkış yaptığında - bilgilerin temizlenmesi
  //console.log("onUserLoggedOutSaga - oturum kapatıldı");
  const state = yield select();

  state.kprice.usersType = "all";
  state.kprice.depositType = "all";
  state.kprice.users.data = {};
  state.kprice.deposit.data = {};
  state.kprice.spot.data = {};
  state.kprice.products.data = {};
  state.kprice.productsMiniTicker.data = {};
  state.kprice.currencies.data = {};
  state.kprice.withdrawRequests.data = {};

  endSubscription("miniTicker");
}

const onUsersOpen = () => ({ 
  type: ON_USERS_OPEN
});
function* onUsersOpenSaga(action) {//spot verileri tekrar çekilmek istenirse
  //console.log("onUsersOpenSaga");
  const state = yield select();
  
  try {
    yield call(getUsersSaga, {
      payload: {
        type: state.kprice.usersType,
      },
    });
  } catch (error) {}
}

const onFutureProfileOpen = (customerId) => ({
  type: ON_FUTURE_PROFILE_OPEN,
  payload: customerId,
});
function* onFutureProfileOpenSaga(action) {
  //console.log("onFutureProfileOpenSaga");
  const state = yield select();

  try {
    yield call(getFutureProfiles, {
      payload: {
        customerId: action.payload.customerId,
      },
    });
  } catch (error) {}

  try {
    yield call(getFutureProcesses, {
      payload: {
        customerId: action.payload.customerId,
      },
    });
  } catch (error) {}

  yield put(connectCustomerUpdatedManagerAppSyncSubscription({ payload: {customer_id : action.payload.customerId}}));
}

const onFutureProfileClose = () => ({
  type: ON_FUTURE_PROFILE_CLOSE,
});
function* onFutureProfileCloseSaga() {
  //console.log("onFutureProfileCloseSaga");
  const state = yield select();

  state.kprice.futureProfiles.data = {};
  state.kprice.futureProcesses.data = {};

  endSubscription("customer");
}

const onGeneralListOpen = () => ({
  type: ON_GENERAL_LIST_OPEN,
});
function* onGeneralListOpenSaga() {
  //console.log("onGeneralListOpenSaga");
  const state = yield select();

  try {
    yield call(getUsersSaga, {
      payload: {
        type: "status_confirmed",
      },
    });
  } catch (error) {}

  try {
    yield call(getSpotSaga);
  } catch (error) {}

  try {
    yield call(getFutureProfiles, {
      payload: {
        customerId: null,
      },
    });
  } catch (error) {}

  try {
    yield call(getFutureProcesses, {
      payload: {
        customerId: null,
      },
    });
  } catch (error) {}

  yield put(connectCustomerGeneralUpdatedManagerAppSyncSubscription());
}

const onGeneralListClose = () => ({
  type: ON_GENERAL_LIST_CLOSE,
});
function* onGeneralListCloseSaga() {
  //console.log("onGeneralListCloseSaga");
  const state = yield select();

  state.kprice.futureProfiles.data = {};
  state.kprice.futureProcesses.data = {};
  state.kprice.wallet.data = {};
  state.kprice.users.data = {};

  endSubscription("generalCustomer");
}

const onActiveProcessesOpen = () => ({
  type: ON_ACTIVE_PROCESSES_OPEN,
});
function* onActiveProcessesOpenSaga() {
  //console.log("onActiveProcessesOpenSaga");
  const state = yield select();

  try {
    yield call(getFutureProfiles, {
      payload: {
        customerId: null,
      },
    });
  } catch (error) {}

  try {
    yield call(getFutureProcesses, {
      payload: {
        customerId: null,
      },
    });
  } catch (error) {}

  yield put(connectCustomerGeneralUpdatedManagerAppSyncSubscription());
}

const onActiveProcessesClose = () => ({
  type: ON_ACTIVE_PROCESSES_CLOSE,
});
function* onActiveProcessesCloseSaga() {
  //console.log("onActiveProcessesCloseSaga");
  const state = yield select();

  state.kprice.futureProfiles.data = {};
  state.kprice.futureProcesses.data = {};

  endSubscription("generalCustomer");
}

const onChangeUsersType = (newType) => ({
  type: ON_CHANGE_USERS_TYPE,
  payload: newType,
});
function* onChangeUsersTypeSaga(action) {//users type bilgisi güncellenince
  const state = yield select();
  state.kprice.usersType = action.payload.newType;

  try {
    yield call(getUsersSaga, {
      payload: {
        type: action.payload.newType,
      },
    });
  } catch (error) {}
}

const onChangeDepositType = (newType) => ({
  type: ON_CHANGE_DEPOSIT_TYPE,
  payload: newType,
});
function* onChangeDepositTypeSaga(action) {//deposit type bilgisi güncellenince
  const state = yield select();
  state.kprice.depositType = action.payload.newType;

  try {
    yield call(getDepositSaga, {
      payload: {
        type: action.payload.newType,
      },
    });
  } catch (error) {}
}

const onSpotOpen = () => ({ 
  type: ON_SPOT_OPEN
});
function* onSpotOpenSaga(action) {//spot verileri tekrar çekilmek istenirse
  //console.log("wallet ve spot verileri tekrar çekiliyor");
  
  try {
    yield call(getSpotSaga);
  } catch (error) {}
}

const onWithdrawRequestsOpen = () => ({ 
  type: ON_WITHDRAW_REQUESTS_OPEN
});
function* onWithdrawRequestsOpenSaga(action) {//withdraw request verileri çekilmek istenirse
  //console.log("withdraw request sayısı tekrar çekiliyor");
  
  try {
    yield call(getWithdrawRequestsSaga);
  } catch (error) {}
}

const kpriceReducer = (state = initialState, action) => {
  switch(action.type) {
    case GET_PRODUCT_IDS_SUCCESS:
    case GET_PRODUCT_IDS_ERROR:
      //console.log("GET_PRODUCT_IDS Reducer");
      return requestActions(GET_PRODUCT_IDS, "products")(state, action);
    
    case CONNECT_MINI_TICKER_UPDATED_SUCCESS:
    case CONNECT_MINI_TICKER_UPDATED_ERROR:
      //console.log("CONNECT_MINI_TICKER_UPDATED Reducer");
      return requestActions(CONNECT_MINI_TICKER_UPDATED, "productsMiniTicker")(state, action);
    
    case GET_USERS_SUCCESS:
    case GET_USERS_ERROR:
      //console.log("GET_USERS Reducer");
      return requestActions(GET_USERS, "users")(state, action);
    
    case GET_DEPOSIT_SUCCESS:
    case GET_DEPOSIT_ERROR:
      //console.log("GET_DEPOSIT Reducer");
      return requestActions(GET_DEPOSIT, "deposit")(state, action);
  
    case GET_SPOT_SUCCESS:
    case GET_SPOT_ERROR:
      //console.log("GET_SPOT Reducer");
      return requestActions(GET_SPOT, "spot")(state, action);
  
    case GET_WITHDRAW_REQUESTS_SUCCESS:
    case GET_WITHDRAW_REQUESTS_ERROR:
      //console.log("GET_WITHDRAW_REQUESTS Reducer");
      return requestActions(GET_WITHDRAW_REQUESTS, "withdrawRequests")(state, action);
  
    case GET_FUTURE_PROFILES_SUCCESS:
    case GET_FUTURE_PROFILES_ERROR:
      //console.log("GET_FUTURE_PROFILES Reducer");
      return requestActions(GET_FUTURE_PROFILES, "futureProfiles")(state, action);
  
    case GET_FUTURE_PROCESSES_SUCCESS:
    case GET_FUTURE_PROCESSES_ERROR:
      //console.log("GET_FUTURE_PROCESSES Reducer");
      return requestActions(GET_FUTURE_PROCESSES, "futureProcesses")(state, action);
    
    case CONNECT_CUSTOMER_UPDATED_MANAGER_SUCCESS:
    case CONNECT_CUSTOMER_UPDATED_MANAGER_ERROR:
      if (action.key) {
        return requestActions(CONNECT_CUSTOMER_UPDATED_MANAGER, action.key)(state, action);
      } else {
        // Eski davranışı koru veya bir hata fırlat
        //console.error("No key provided for action");
        return state;
      }

    case CONNECT_CUSTOMER_GENERAL_UPDATED_MANAGER_SUCCESS:
    case CONNECT_CUSTOMER_GENERAL_UPDATED_MANAGER_ERROR:
      if (action.key) {
        return requestActions(CONNECT_CUSTOMER_GENERAL_UPDATED_MANAGER, action.key)(state, action);
      } else {
        // Eski davranışı koru veya bir hata fırlat
        //console.error("No key provided for action");
        return state;
      }
    
    default:
      return state;
  }
}

export {
  startInit,
  onUserLoadedInit,
  onUserLoggedOut,
  kpriceReducer,
  kpriceSaga,
  onUsersOpen,
  onChangeUsersType,
  onChangeDepositType,
  onSpotOpen,
  onFutureProfileOpen,
  onFutureProfileClose,
  onGeneralListOpen,
  onGeneralListClose,
  onActiveProcessesOpen,
  onActiveProcessesClose,
  onWithdrawRequestsOpen
};